import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { renderIcon } from '@utils/renderIcon';
import { InputUploadInterface } from '@components/InputUpload/interfaces';

const Input = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputUpload: React.FC<InputUploadInterface> = ({
  onChange,
  name,
  text,
}) => {
  return (
    <Button
      // variant="text"
      startIcon={renderIcon({ icon: 'CloudUpload' })}
      component="label"
      color="primary"
    >
      {text}
      <Input type="file" onChange={onChange} name={name} />
    </Button>
  );
};

export default InputUpload;
