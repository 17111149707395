export const translationEN = {
  translation: {
    register: 'Register',
    hi: 'Hi!',
    lorem:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    loremShort:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    fullName: 'Full Name:',
    phoneNumber: 'Phone Number:',
    email: 'Email',
    password: 'Password',
    agree1: 'I agree to…',
    agree2:
      'I agree to participate in the lottery. More information at: example.pl',
    signUp: 'Sign Up',
    logIn: 'Log In',
    homePage: 'Home Page',
    login: 'Login',
    logOut: 'Log Out',
    quote1:
      'Programs must be written for people to read, and only incidentally for machines to execute. – Harold Abelson',
    quote2:
      'Any fool can write code that a computer can understand. Good programmers write code that humans can understand. – Martin Fowler',
    quote3:
      'Code is like humor. When you have to explain it, it’s bad. – Cory House',
    quote4: 'First, solve the problem. Then, write the code. – John Johnson',
    quote5: 'Simplicity is the soul of efficiency. – Austin Freeman',
    quote6:
      "Programming isn't about what you know; it's about what you can figure out. – Chris Pine",
    quote7:
      'The best error message is the one that never shows up. – Thomas Fuchs',
    quote8:
      'The most important property of a program is whether it accomplishes the intention of its user. – C.A.R. Hoare',
    privacy_policy_0:
      'This Privacy Policy describes the principles of personal data processing by [Your Company], with its registered office at [address], registered in the [name of the register] under number [register number].',
    privacy_policy_1: '1. Personal Data Administrator',
    privacy_policy_1_1:
      'The administrator of your personal data is [Your Company] with its registered office at [address]. You can contact us via email: [email address] or by phone: [phone number].',
    privacy_policy_2: '2. Purposes and Legal Basis for Data Processing',
    privacy_policy_2_1:
      'We process your personal data for the following purposes:',
    privacy_policy_2_1_1:
      '- Service provision: Data processing is necessary for the performance of a contract to which you are a party or to take action at your request before entering into a contract (Art. 6(1)(b) GDPR).',
    privacy_policy_2_1_2:
      '- Marketing: For the purpose of sending marketing information with your consent (Art. 6(1)(a) GDPR).',
    privacy_policy_2_1_3:
      '- Compliance with legal obligations: We process data to fulfill legal obligations, e.g., related to accounting (Art. 6(1)(c) GDPR).',
    privacy_policy_2_1_4:
      '- Legitimate interests: For conducting analyses, statistics, and safeguarding against claims (Art. 6(1)(f) GDPR).',
    privacy_policy_3: '3. Types of Processed Personal Data',
    privacy_policy_3_1: 'We process the following categories of personal data:',
    privacy_policy_3_1_1: '- Identification data (e.g., name and surname)',
    privacy_policy_3_1_2: '- Contact data (e.g., email address, phone number)',
    privacy_policy_3_1_3:
      '- Data related to contract execution (e.g., order history)',
    privacy_policy_4: '4. Recipients of Personal Data',
    privacy_policy_4_1:
      'Your personal data may be transferred to the following categories of recipients:',
    privacy_policy_4_1_1:
      '- Entities processing data on our behalf (e.g., IT companies, accounting firms)',
    privacy_policy_4_1_2: '- Public authorities, if required by applicable law',
    privacy_policy_4_1_3:
      '- Other entities cooperating under an agreement with [Your Company]',
    privacy_policy_5: '5. Transfer of Data Outside the EEA',
    privacy_policy_5_1:
      'Your personal data will not be transferred outside the European Economic Area (EEA) unless necessary, and we ensure an adequate level of data protection.',
    privacy_policy_6: '6. Data Retention Period',
    privacy_policy_6_1:
      'We retain personal data for the period necessary to achieve the purposes of processing or until consent is withdrawn if processing is based on consent. This period may be extended by the time necessary to secure or pursue potential claims.',
    privacy_policy_7: '7. Your Rights',
    privacy_policy_7_1:
      'You have the following rights regarding the processing of your personal data:',
    privacy_policy_7_1_1: '- Right of access to data',
    privacy_policy_7_1_2: '- Right to rectification of data',
    privacy_policy_7_1_3:
      '- Right to erasure of data (“right to be forgotten”)',
    privacy_policy_7_1_4: '- Right to restrict processing',
    privacy_policy_7_1_5: '- Right to data portability',
    privacy_policy_7_1_6: '- Right to object to processing',
    privacy_policy_7_1_7: '- Right to withdraw consent',
    privacy_policy_7_2:
      'To exercise your rights, please contact us at: [email address].',
    privacy_policy_8: '8. Data Security',
    privacy_policy_8_1:
      'We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, loss, destruction, or alteration.',
    privacy_policy_9: '9. Changes to the Privacy Policy',
    privacy_policy_9_1:
      'We reserve the right to make changes to this Privacy Policy. We will inform you of any changes by posting the new version of the policy on our website.',
    privacy_policy_10: '10. Contact',
    privacy_policy_10_1:
      'If you have any questions regarding the processing of your personal data, please contact us at: [email address] or by phone: [phone number].',
    privacy_policy_10_2:
      'Remember to complete this document with details specific to your business and seek professional legal advice before publishing.',
    user_creation_error: 'Error',
    campaign_not_found: 'The campaign does not exist!',
    campaign_inactive: 'The campaign is inactive.',
    account_already_exists:
      'Unfortunately, you already have an account with this bookmaker.',
  },
};
