import { useCallback } from 'react';
import useNotifier from '@utils/useNotifier';
import { useAuthStore } from '@store/useAuthStore/useAuthStore';
import { errorMessages } from '@messages/errorMessages';

const useSuperAdminCheck = () => {
  const { notify } = useNotifier();
  const { superAdmin } = useAuthStore();

  const checkSuperAdmin = useCallback(
    (callback: () => void) => {
      if (superAdmin) {
        callback();
      } else {
        notify(errorMessages.noSuperAdminPermissions, 'error');
      }
    },
    [superAdmin, notify],
  );

  return { checkSuperAdmin };
};

export default useSuperAdminCheck;
