import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { baseStyles } from '@components/Main/styles';
import { useLanguageStore } from '@store/useLanguageStore/useLanguageStore';
import i18n from '@src/i18n';
import { changeLanguage } from '@utils/language.utils';
import Box from '@mui/material/Box';

const Main: React.FC = () => {
  const { locale, setLocale } = useLanguageStore();
  const params = useParams<{ locale: string }>();

  useEffect(() => {
    const localeUrl = params.locale;

    if (localeUrl && localeUrl !== i18n.language) {
      setLocale(localeUrl);
      changeLanguage(localeUrl);
    }
  }, [params.locale, setLocale]);

  useEffect(() => {
    if (locale && locale !== i18n.language) {
      changeLanguage(locale);
    }
  }, [locale]);

  return (
    <Box sx={baseStyles}>
      <Outlet />
    </Box>
  );
};

export default Main;
