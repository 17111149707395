import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePickerInterface } from '@components/DatePicker/interfaces';
import { pl } from 'date-fns/locale';

const DatePicker: React.FC<DatePickerInterface> = ({
  disabled,
  label,
  name,
  value,
  onChange,
  error,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <DateTimePicker
        label={label}
        sx={{ width: '100%' }}
        disabled={disabled}
        name={name}
        value={value}
        disablePast
        onChange={onChange}
        slotProps={{ textField: { error: error } }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
