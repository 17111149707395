import React from 'react';
import { TypographyInterface } from '@components/Typography/interfaces';
import TypographyMui from '@mui/material/Typography';
import { useTheme, PaletteColor } from '@mui/material/styles';

const threeDotsStyle = {
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Typography: React.FC<TypographyInterface> = ({
  variant = 'body1',
  align = 'inherit',
  gutterBottom,
  text,
  color,
  bold,
  oneLine,
}) => {
  const theme = useTheme();
  const getColor = (color: string | undefined) => {
    if (!color) return 'inherit';
    const paletteColor = theme.palette[
      color as keyof typeof theme.palette
    ] as PaletteColor;
    if (paletteColor && paletteColor.main) {
      return paletteColor.main;
    }
    return color;
  };

  return (
    <TypographyMui
      variant={variant}
      align={align}
      gutterBottom={gutterBottom}
      sx={{
        color: getColor(color),
        ...(bold && { fontWeight: 600 }),
        ...(oneLine && threeDotsStyle),
      }}
    >
      {text}
    </TypographyMui>
  );
};

export default Typography;
