import i18n, { InitOptions } from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translationCZ } from './locales/cz/translation';
import { translationEN } from './locales/en/translation';
import { translationPL } from './locales/pl/translation';

const resources = {
  cz: { ...translationCZ },
  en: { ...translationEN },
  pl: { ...translationPL },
};

const options: InitOptions = {
  resources,
  fallbackLng: 'pl',
  debug: false,
  supportedLngs: ['cz', 'en', 'pl'],
  detection: {
    order: ['path', 'navigator'],
    lookupFromPathIndex: 0,
  },
  ns: ['translation'],
  defaultNS: 'translation',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: false,
  },
};

i18n.use(initReactI18next).init(options).catch();

export default i18n;
