import { useSnackbar, VariantType } from 'notistack';

const useNotifier = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notify = (
    message: string,
    variant: VariantType = 'default',
    position: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'center' | 'right';
    } = {
      vertical: 'bottom',
      horizontal: 'left',
    },
  ) => {
    enqueueSnackbar(message, { variant, anchorOrigin: position });
  };

  return { notify };
};

export default useNotifier;
