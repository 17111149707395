import React from 'react';
import Button from '@components/Button';
import { CopyToClipboardInterface } from '@components/CopyToClipboard/interfaces';
import useNotifier from '@utils/useNotifier';
import { successMessages } from '@messages/successMessages';
import { errorMessages } from '@messages/errorMessages';

const CopyToClipboard: React.FC<CopyToClipboardInterface> = ({
  label = 'Kopiuj link',
  text,
  variant = 'contained',
  size = 'large',
}) => {
  const { notify } = useNotifier();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      notify(successMessages.copied, 'success');
    } catch (err) {
      notify(errorMessages.failedCopy, 'error');
    }
  };

  return (
    <Button
      text={label}
      startIcon="ContentCopy"
      onClick={handleCopy}
      variant={variant}
      size={size}
    />
  );
};

export default CopyToClipboard;
