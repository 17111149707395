import { DocumentReference } from '@firebase/firestore';
import { collection, db, getDocs, query, where } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { Campaign } from '@store/useCampaignStore/interfaces';

export const getCampaignsByBookmaker = async (
  bookmakerRef: DocumentReference,
) => {
  const campaignQuery = query(
    collection(db, Collection.CAMPAIGNS),
    where('bookmakerRef', '==', bookmakerRef),
  );

  const snapshot = await getDocs(campaignQuery);
  if (snapshot.empty) return [];

  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  if (list.length) {
    return list as Campaign[];
  }
};
