import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from '@src/App';
import CssBaseline from '@mui/material/CssBaseline';
import i18n from '@src/i18n';
import reportWebVitals from './reportWebVitals';
import theme from '@styles/theme';
import { I18nextProvider } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from '@mui/material/styles';
import { useAuthStore } from '@store/useAuthStore/useAuthStore';
import { SnackbarProvider } from 'notistack';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const Root = () => {
  const { fetchUser, initialized } = useAuthStore();
  const { getLayouts } = useSettingStore();

  useEffect(() => {
    fetchUser();
    getLayouts();
  }, []);

  if (!initialized) {
    return <LinearProgress />;
  }

  return (
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={8} autoHideDuration={6000}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </StrictMode>
  );
};

root.render(<Root />);

reportWebVitals();
