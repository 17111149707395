import { create } from 'zustand';
import { DEFAULT_USER_CAMPAIGN_STATE } from '@store/useUserCampaignStore/consts';
import { UserCampaignsStore } from '@store/useUserCampaignStore/interfaces';
import { getUserCampaigns } from '@utils/api/userCampaigns/getUserCampaigns';

export const useUserCampaignStore = create<UserCampaignsStore>((set) => ({
  ...DEFAULT_USER_CAMPAIGN_STATE,

  getUserCampaigns: async (campaignRef) => {
    try {
      const userCampaigns = await getUserCampaigns(campaignRef);
      set({ userCampaigns });
    } catch (error) {
      console.error('Błąd pobierania danych userCampaigns:', error);
    }
  },
}));
