import React from 'react';
import Typography from '@components/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { TitleSectionInterface } from '@components/TitleSection/interfaces';
import Button from '@components/Button';

const TitleSection: React.FC<TitleSectionInterface> = ({
  text,
  onClick,
  children,
}) => (
  <Box sx={{ marginBottom: 5 }}>
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        flexFlow: 'nowrap row',
        alignItems: 'center',
        marginBottom: 2,
      }}
    >
      {onClick && (
        <Button
          variant="outlined"
          startIcon="ArrowBack"
          text="Powrót"
          onClick={onClick}
        />
      )}
      <Typography text={text} variant="h4" />

      {children && <Box sx={{ marginLeft: 'auto' }}>{children}</Box>}
    </Box>
    <Divider />
  </Box>
);

export default TitleSection;
