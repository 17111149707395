import { DocumentReference } from '@firebase/firestore';
import { Collection } from '@enums/collection';
import { collection, db, getDocs, query, where } from '@src/firebase-config';

export const getUserCampaignsByUserBookmaker = async (
  userBookmakerRef: DocumentReference,
) => {
  const campaignsQuery = query(
    collection(db, Collection.USER_CAMPAIGNS),
    where('userBookmakerRef', '==', userBookmakerRef),
  );

  const snapshot = await getDocs(campaignsQuery);
  if (snapshot.empty) return [];

  return snapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
};
