import React from 'react';
import { SelectInterface } from '@components/Select/interfaces';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const Select: React.FC<SelectInterface> = ({
  label,
  value,
  onChange,
  name,
  options,
  disabled = false,
  error,
  loading = false,
}) => {
  return (
    <FormControl
      fullWidth
      disabled={disabled || loading}
      size="medium"
      error={error}
    >
      <InputLabel>{label}</InputLabel>
      <SelectMui
        value={loading ? '' : value}
        label={label}
        onChange={onChange}
        name={name}
        sx={{ backgroundColor: 'white' }}
      >
        {loading
          ? 'Loading...'
          : options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
      </SelectMui>
    </FormControl>
  );
};

export default Select;
