import { create } from 'zustand';
import { User, UserStore } from '@store/useUserStore/interfaces';
import { DEFAULT_USER_STATE } from '@store/useUserStore/consts';
import { getUsers } from '@utils/api/users/getUsers';
import { getUser } from '@utils/api/users/getUser';
import { getUserRef } from '@utils/api/users/getUserRef';

export const useUserStore = create<UserStore>((set) => ({
  ...DEFAULT_USER_STATE,

  getUser: async (uid) => {
    try {
      const userRef = getUserRef(uid);
      const snapshot = await getUser(uid);

      const user = {
        id: uid,
        ref: userRef,
        ...snapshot.data(),
      } as User;

      set({ user });
    } catch (error) {
      console.error('Błąd pobierania danych użytkownika:', error);
    }
  },
  getUsers: async () => {
    set({ loading: true });

    try {
      const users = await getUsers();
      set({ users });
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error('Błąd pobierania listy użytkowników:', error);
    }
  },
  setUsers: (users) => set({ users }),
}));
