import React from 'react';
import { CheckboxInterface } from '@components/Checkbox/interfaces';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const Checkbox: React.FC<CheckboxInterface> = ({
  label,
  checked,
  onChange,
  name,
  required = false,
  error = false,
}) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        required={required}
        sx={{ color: error ? 'error.main' : 'primary.main' }}
        control={
          <MuiCheckbox checked={checked} onChange={onChange} name={name} />
        }
        label={label}
      />
    </FormControl>
  );
};

export default Checkbox;
