import { getDoc } from '@src/firebase-config';
import { DocumentReference } from '@firebase/firestore';
import { Campaign } from '@store/useCampaignStore/interfaces';

export const getCampaign = async (campaignRef: DocumentReference) => {
  const campaignDoc = await getDoc(campaignRef);
  if (campaignDoc.exists()) {
    return campaignDoc.data() as Campaign;
  }

  throw new Error('Campaign not found');
};
