import React from 'react';
import TitleSection from '@components/TitleSection';

export const Dashboard = () => {
  return (
    <>
      <TitleSection text="Dashboard" />
    </>
  );
};
