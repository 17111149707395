import React, { Fragment } from 'react';
import { isEmptyObject } from '@utils/helpers';
import { uploadImage } from '@utils/uploadImage';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Card from '@mui/material/Card';
import Form from '@components/Form';
import FormAlert from '@components/FormAlert';
import Grid from '@mui/material/Grid';
import Input from '@components/Input';
import TitleSection from '@components/TitleSection';
import InputUpload from '@components/InputUpload';
import { validationSchemaAddBookmaker } from '@src/validationSchema/validations';
import useNotifier from '@utils/useNotifier';
import { successMessages } from '@messages/successMessages';
import { Variant } from '@store/useBookmakerStore/interfaces';
import ButtonIcon from '@components/ButtonIcon';
import Typography from '@components/Typography';
import Divider from '@mui/material/Divider';

interface FormValues {
  name: string;
  image?: File;
  variants: Variant[];
}

export const AddBookmaker = () => {
  const { notify } = useNotifier();
  const { addBookmaker, bookmaker, loading } = useBookmakerStore();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: bookmaker.name,
      image: undefined,
      variants: [{ value: '', label: '' }],
    },
    validationSchema: validationSchemaAddBookmaker,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      if (values.image) {
        const urlImage = await uploadImage(values.image, 'bookmakers');
        const updatedValues = {
          ...values,
          image: urlImage,
          variants: values.variants,
        };
        addBookmaker(updatedValues);
        notify(successMessages.addBookmaker, 'success');
        resetForm();
      }
    },
  });

  return (
    <>
      <TitleSection text="Dodaj bukmachera" />

      <Card sx={{ padding: 2, width: 600 }}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {!isEmptyObject(formik.errors) && (
                <Grid item xs={12}>
                  <FormAlert errors={formik.errors} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Input
                  name="name"
                  label="Nazwa bukmachera"
                  value={formik.values.name}
                  error={Boolean(formik.errors.name)}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} justifyContent="center">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexFlow: 'nowrap row',
                  }}
                >
                  <InputUpload
                    name="image"
                    text="Dodaj logotyp"
                    onChange={(e) => {
                      formik.setFieldValue(
                        'image',
                        e.target.files ? e.target.files[0] : null,
                      );
                    }}
                  />
                  <Typography
                    text={formik.values.image?.name}
                    variant="body1"
                    color="success"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Divider sx={{ marginBottom: 2 }} />
                    <Typography text="Dodaj warianty" variant="caption" />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="variants"
                      render={({ push, remove }) => (
                        <Grid container spacing={2}>
                          {formik.values.variants.map((_, index) => (
                            <Fragment key={index}>
                              <Grid item xs={6}>
                                <Input
                                  error={Boolean(
                                    formik.errors.variants &&
                                      formik.errors.variants[index] &&
                                      (formik.errors.variants[index] as Variant)
                                        .label,
                                  )}
                                  name={`variants[${index}].label`}
                                  label="Klucz"
                                  value={formik.values.variants[index].label}
                                  size="small"
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <Input
                                  error={Boolean(
                                    formik.errors.variants &&
                                      formik.errors.variants[index] &&
                                      (formik.errors.variants[index] as Variant)
                                        .value,
                                  )}
                                  name={`variants[${index}].value`}
                                  label="Wartość"
                                  value={formik.values.variants[index].value}
                                  size="small"
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <ButtonIcon
                                    icon="RemoveCircleOutline"
                                    onClick={() => remove(index)}
                                  />
                                </Box>
                              </Grid>
                            </Fragment>
                          ))}
                          <Grid item xs={12}>
                            <Button
                              text="Dodaj wariant"
                              variant="text"
                              startIcon="AddCircleOutline"
                              onClick={() => push({ value: '', label: '' })}
                            />
                          </Grid>
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    loading={loading}
                    text="Dodaj bukmachera"
                    color="primary"
                    type="submit"
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Card>
    </>
  );
};
