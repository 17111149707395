import { DocumentReference } from '@firebase/firestore';
import { collection, db, getDocs, query, where } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { Code } from '@store/useCodeStore/interfaces';

export const getCodesByCampaign = async (campaignRef: DocumentReference) => {
  const q = query(
    collection(db, Collection.CODES),
    where('campaignRef', '==', campaignRef),
  );

  const snapshot = await getDocs(q);
  if (snapshot.empty) return [];

  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  if (list.length > 0) {
    return list as Code[];
  }
};
