import { create } from 'zustand';
import { DEFAULT_SETTING_STATE } from '@store/useSettingStore/consts';
import { SettingStore } from '@store/useSettingStore/interfaces';
import { getLayouts } from '@utils/api/settings/getLayouts';
import { addLayout } from '@utils/api/settings/addLayout';
import { updateLayout } from '@utils/api/settings/updateLayout';
import { deleteLayout } from '@utils/api/settings/deleteLayout';
import { getDocumentRef, getFunction, updateDocument } from '@utils/helpers';
import { FirebaseFunctions } from '@enums/functions';
import { getBookmakerSettings } from '@utils/api/settings/getBookmakerSettings';
import { Collection, SettingsBookmakerID } from '@enums/collection';

const forceUserCodeAdditionFunction = getFunction(
  FirebaseFunctions.FORCE_USER_CODE_ADDITION,
);
const simulateVerifiedFunction = getFunction(
  FirebaseFunctions.SIMULATE_VERIFIED,
);
const addAdminRoleFunction = getFunction(
  FirebaseFunctions.ADD_ADMIN_ROLE_TO_USER,
);
const forceGetUsersFromFortunaBookmakerFunction = getFunction(
  FirebaseFunctions.FORCE_GET_USERS_FROM_FORTUNA_BOOKMAKER,
);

export const useSettingStore = create<SettingStore>((set, get) => ({
  ...DEFAULT_SETTING_STATE,

  getLayouts: async () => {
    const layouts = await getLayouts();
    set({ layouts });
  },
  addLayout: async (name, component) => {
    set({ loading: true });
    const layoutRef = await addLayout(name, component);
    await updateLayout(layoutRef, { id: layoutRef.id, ref: layoutRef });
    get().getLayouts();
    set({ loading: false });
  },
  addAdminRole: async (uid) => {
    set({ loading: true });
    await addAdminRoleFunction(uid);
    set({ loading: false });
  },
  updateLayout: async (layoutRef, data) => {
    await updateLayout(layoutRef, data);
    get().getLayouts();
  },
  deleteLayout: async (layoutRef) => {
    await deleteLayout(layoutRef);
    get().getLayouts();
  },
  getFortunaPLSettings: async () => {
    const docRef = getDocumentRef(
      Collection.SETTINGS_BOOKMAKER,
      SettingsBookmakerID.FORTUNA_PL,
    );
    const fortunaPL = await getBookmakerSettings(docRef);

    set({ fortunaPL: { ...fortunaPL, docRef } });
  },
  updateBookmakerSettings: async (ref, data) => {
    set({ loadingUpdate: true });
    await updateDocument(ref, data);
    set({ loadingUpdate: false });
  },
  forceUserCodeAddition: async () => {
    set({ loading: true });
    await forceUserCodeAdditionFunction();
    set({ loading: false });
  },
  forceGetUsersFromFortunaBookmaker: async () => {
    try {
      set({ loading: true });
      const fortuna = await forceGetUsersFromFortunaBookmakerFunction();
      console.log(fortuna.data);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.log(error);
    }
  },
  simulateVerified: async (codes) => {
    set({ loadingSimulate: true });
    await simulateVerifiedFunction(codes);
    set({ loadingSimulate: false });
  },
}));
