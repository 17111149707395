export enum StatusCode {
  AVAILABLE = 'available',
  USED = 'used',
}

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  AVAILABLE = 'available',
}
