import { create } from 'zustand';
import { DEFAULT_REGISTER_STATE } from './consts';
import {
  CreateUserResponse,
  RegisterStore,
  VerifyRecaptchaResponse,
} from './interfaces';
import { FirebaseFunctions } from '@enums/functions';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { getFunction } from '@utils/helpers';
import { DialogsID } from '@enums/dialogs';

const createUserFunction = getFunction(FirebaseFunctions.CREATE_USER);
const verifyRecaptchaFunction = getFunction(FirebaseFunctions.VERIFY_RECAPTCHA);

export const useRegisterStore = create<RegisterStore>((set) => ({
  ...DEFAULT_REGISTER_STATE,

  registerUserAnonymous: async (campaignID, token, translation) => {
    set({ loading: true });

    const registerState = useRegisterStore.getState();

    try {
      const verificationResponse = (await verifyRecaptchaFunction({
        token,
      })) as VerifyRecaptchaResponse;

      if (verificationResponse.data.success) {
        const result = (await createUserFunction({
          registerFields: {
            name: registerState.name,
            phoneNumber: registerState.phoneNumber,
            email: registerState.email,
            agreement_1: registerState.agreement_1,
            agreement_2: registerState.agreement_2,
          },
          campaignID,
        })) as CreateUserResponse;

        if (result.data.message) {
          useDialogState.getState().setDialog({
            id: DialogsID.REGISTER,
            title: translation('user_creation_error'),
            text: translation(result.data.message),
          });
        }

        if (result.data.refLinkUrl) {
          window.location.replace(result.data.refLinkUrl);
        }
      } else {
        console.error(
          'Error validation reCAPTCHA:',
          verificationResponse.data.message,
        );
      }

      set({ loading: false });
    } catch (error) {
      console.error('Error register:', error);
      set({ loading: false });
    }
  },
  setField: (field, value) =>
    set((state) => ({
      ...state,
      [field]: value,
    })),
  resetFields: () => set(() => DEFAULT_REGISTER_STATE),
}));
