import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthStore } from '@store/useAuthStore/useAuthStore';
import { Routes } from '@enums/routes';
import { ProtectedRouteInterface } from '@components/ProtectedRoute/interfaces';

const ProtectedRoute: React.FC<ProtectedRouteInterface> = ({
  requiredRole,
}) => {
  const location = useLocation();
  const { user, role } = useAuthStore();

  if (!user || role !== requiredRole) {
    return <Navigate to={Routes.LOGIN} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
