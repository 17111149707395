import React from 'react';
import ButtonIcon from '@components/ButtonIcon';
import { DialogsID } from '@enums/dialogs';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { EditBookmakerInterface } from '@components/BookmakerItem/interfaces';
import useSuperAdminCheck from '@utils/useSuperAdminCheck';

const EditBookmaker: React.FC<EditBookmakerInterface> = ({ bookmakerRef }) => {
  const { setDialog } = useDialogState();
  const { checkSuperAdmin } = useSuperAdminCheck();

  const handleEditBookmaker = () => {
    checkSuperAdmin(() =>
      setDialog({
        id: DialogsID.EDIT_BOOKMAKER,
        title: 'Edytuj bukmachera',
        params: { bookmakerRef },
      }),
    );
  };

  return <ButtonIcon icon="Edit" onClick={handleEditBookmaker} />;
};

export default EditBookmaker;
