import React, { useState } from 'react';
import Dialog from '@components/Dialog';
import { useDialogState } from '@store/useDialogState/useDialogState';
import Button from '@components/Button';
import Textarea from '@components/Textarea';
import { useFormik } from 'formik';
import { isDateBeforeNow, isEmptyObject, parseCodes } from '@utils/helpers';
import Box from '@mui/material/Box';
import { getCampaignRef } from '@utils/api/campaigns/getCampaignRef';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import { useCodeStore } from '@store/useCodeStore/useCodeStore';
import { useUserCampaignStore } from '@store/useUserCampaignStore/useUserCampaignStore';
import { DialogsID } from '@enums/dialogs';
import { validationSchemaDialogAddCodes } from '@src/validationSchema/validations';
import Grid from '@mui/material/Grid';
import FormAlert from '@components/FormAlert';
import { successMessages } from '@messages/successMessages';
import useNotifier from '@utils/useNotifier';
import { errorMessages } from '@messages/errorMessages';
import { updateCampaign } from '@utils/api/campaigns/updateCampaign';
import { Status } from '@enums/status';
import Checkbox from '@components/Checkbox';

interface Props {
  campaignID: string | undefined;
}

const DialogAddCodes: React.FC<Props> = ({ campaignID }) => {
  const dialogId = DialogsID.ADD_CODES;
  const [loading, setLoading] = useState(false);

  const { closeDialog, setDialog, dialogs } = useDialogState();
  const { notify } = useNotifier();
  const { campaign, getCampaign } = useCampaignStore();
  const { addCodes, getCodesByCampaign } = useCodeStore();
  const { getUserCampaigns } = useUserCampaignStore();

  const formik = useFormik({
    initialValues: {
      codes: '',
      active: false,
    },
    validationSchema: validationSchemaDialogAddCodes,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const codes = parseCodes(values.codes);

      if (campaignID) {
        const campaignRef = getCampaignRef(campaignID);
        getCampaign(campaignRef);

        if (campaign.endAt) {
          if (isDateBeforeNow(campaign?.endAt)) {
            resetForm();
            setLoading(false);

            closeDialog();
            notify(errorMessages.endCampaign, 'error');
          } else {
            if (values.active === true) {
              await updateCampaign(campaignRef, { status: Status.ACTIVE });
            }

            getCampaign(campaignRef);
            await addCodes(campaignRef, codes);
            await getCodesByCampaign(campaignRef);
            await getUserCampaigns(campaignRef);

            resetForm();
            setLoading(false);

            closeDialog();
            notify(successMessages.addCodes, 'success');
          }
        }
      }
    },
  });

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <Button
        text="dodaj"
        startIcon="QrCodeScanner"
        onClick={() => {
          setDialog({ id: dialogId, title: 'Dodaj kody' });
        }}
        variant="outlined"
      />
      {dialogs[dialogId]?.open && (
        <Dialog
          confirmAction={handleConfirm}
          id={dialogId}
          confirmName="Dodaj"
          width={500}
          loading={loading}
          closeName="Anuluj"
          closeAction={() => {
            formik.resetForm();
            closeDialog();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'wrap column',
              gap: 2,
              padding: 1,
            }}
          >
            {!isEmptyObject(formik.errors) && (
              <Grid item xs={12}>
                <FormAlert errors={formik.errors} />
              </Grid>
            )}
            <Textarea
              error={Boolean(formik.errors.codes)}
              name="codes"
              onChange={formik.handleChange}
              value={formik.values.codes}
            />
            <Checkbox
              label="Czy aktywować kampanie?"
              name="active"
              checked={formik.values.active}
              onChange={formik.handleChange}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default DialogAddCodes;
