import React from 'react';
import { ButtonInterface } from '@components/Button/interfaces';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { renderIcon } from '@utils/renderIcon';

const Button: React.FC<ButtonInterface> = ({
  loading,
  text,
  onClick,
  type,
  disabled = false,
  color,
  startIcon,
  endIcon,
  variant = 'contained',
  component = 'button',
  size = 'large',
}) => {
  return (
    <MuiButton
      variant={variant}
      onClick={onClick}
      type={type}
      disabled={disabled}
      color={color}
      startIcon={startIcon && renderIcon({ icon: startIcon })}
      endIcon={endIcon && renderIcon({ icon: endIcon })}
      component={component}
      size={size}
    >
      {loading && (
        <CircularProgress size={20} color="inherit" sx={{ marginRight: 1 }} />
      )}
      {text}
    </MuiButton>
  );
};

export default Button;
