import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CopyToClipboard from '@components/CopyToClipboard';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TitleSection from '@components/TitleSection';
import Typography from '@components/Typography';
import { chipStatus } from '@utils/chipStatus';
import { formatDate, generateLink } from '@utils/helpers';
import { getUserRef } from '@utils/api/users/getUserRef';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserBookmakerStore } from '@store/useUserBookmakerStore/useUserBookmakerStore';
import { useUserStore } from '@store/useUserStore/useUserStore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { renderIcon } from '@utils/renderIcon';
import CustomLink from '@components/CustomLink';

export const ListUserItem = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, getUser } = useUserStore();
  const { userBookmakers, getUserBookmakers } = useUserBookmakerStore();

  useEffect(() => {
    const fetch = async () => {
      if (params.userId) {
        const userRef = getUserRef(params.userId);
        await getUser(params.userId);
        await getUserBookmakers(userRef);
      }
    };
    fetch().catch();
  }, []);

  const handleBack = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <TitleSection onClick={() => handleBack(`/admin-panel/list-users`)} />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography text="ID użytkownika" variant="caption" />
                  <Typography text={params.userId} variant="h6" />
                </Grid>
                <Grid item xs={12}>
                  <Typography text="Imie i nazwisko" variant="caption" />
                  <Typography text={user.name} variant="h6" />
                </Grid>
                <Grid item xs={12}>
                  <Typography text="E-mail" variant="caption" />
                  <Typography text={user.email} variant="h6" />
                </Grid>
                <Grid item xs={12}>
                  <Typography text="Numer telefonu" variant="caption" />
                  <Typography text={user.phoneNumber} variant="h6" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{ padding: 3 }}>
              <Typography text="Data dołączenia" variant="caption" />
              <Typography
                text={
                  user.createdAt
                    ? formatDate(user.createdAt, 'dd-MM-yyyy - HH:mm')
                    : '---'
                }
                variant="h6"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {userBookmakers.map((userBookmaker, i) => {
            return (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={renderIcon({ icon: 'KeyboardArrowDown' })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      padding: '0 48px 0 0',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        flexFlow: 'nowrap row',
                      }}
                    >
                      <Avatar
                        alt={userBookmaker.name}
                        src={userBookmaker.image}
                      />
                      <Typography text={userBookmaker.name} variant="h5" />
                    </Box>
                    <Box>{chipStatus(String(userBookmaker.verified))}</Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {userBookmaker.campaigns.map((item, key) => {
                      const refLinkWithVariantId = generateLink(
                        item.campaign.refLink,
                        item.campaign.variant,
                        item.variantID,
                      );

                      return (
                        <Grid item xs={12} key={key}>
                          <Paper
                            variant="outlined"
                            sx={{
                              display: 'flex',
                              padding: 2,
                              width: '100%',
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={2}>
                                <Typography
                                  text="Status kampanii"
                                  variant="caption"
                                />
                                <Box
                                  sx={{
                                    width: '100%',
                                    marginTop: '4px',
                                  }}
                                >
                                  {chipStatus(item.campaign.status)}
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  text="Nazwa kampanii"
                                  variant="caption"
                                />
                                <Typography
                                  text={item.campaign.name}
                                  variant="h6"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  text="ID Kampanii"
                                  variant="caption"
                                />
                                <CustomLink
                                  to={`/admin-panel/list-campaigns/${item.campaignRef.id}`}
                                  label={item.campaignRef.id}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  text="Koniec kampanii"
                                  variant="caption"
                                />
                                <Typography
                                  text={
                                    item.campaign.endAt
                                      ? formatDate(
                                          item.campaign.endAt,
                                          'dd-MM-yyyy - HH:mm',
                                        )
                                      : '---'
                                  }
                                  color="warning"
                                  variant="h6"
                                />
                              </Grid>
                              {!userBookmaker.verified && (
                                <>
                                  <Grid item xs={6}>
                                    <Typography
                                      text="Reflink"
                                      variant="caption"
                                    />
                                    <Typography
                                      text={item.campaign.refLink}
                                      variant="h6"
                                      oneLine
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography
                                      text="Variant"
                                      variant="caption"
                                    />
                                    <Typography
                                      text={item.campaign.variant}
                                      variant="h6"
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        height: '100%',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                      }}
                                    >
                                      <CopyToClipboard
                                        label="Kopiuj reflink"
                                        text={refLinkWithVariantId}
                                        variant="outlined"
                                      />
                                    </Box>
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12}>
                                <Typography
                                  text="Variant ID"
                                  variant="caption"
                                />
                                <Typography
                                  text={item.variantID}
                                  variant="h6"
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Typography
                                  text="Kod promocyjny"
                                  variant="caption"
                                />
                                <Typography
                                  text={
                                    item.code
                                      ? item.code
                                      : item.pending
                                        ? 'Oczekiwanie na kod...'
                                        : 'Brak'
                                  }
                                  variant="h6"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  text="Data weryfikacji"
                                  variant="caption"
                                />
                                <Typography
                                  text={
                                    item.verifiedDate
                                      ? formatDate(
                                          item.verifiedDate,
                                          'd MMMM, yyyy - HH:mm',
                                        )
                                      : 'Brak'
                                  }
                                  variant="h6"
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
