import { create } from 'zustand';
import { DEFAULT_CAMPAIGN_STATE } from '@store/useCampaignStore/consts';
import { Campaign, CampaignsStore } from '@store/useCampaignStore/interfaces';
import { DocumentReference } from '@firebase/firestore';
import { getCampaignsByBookmaker } from '@utils/api/campaigns/getCampaignsByBookmaker';
import { addCampaign } from '@utils/api/campaigns/addCampaign';
import { getCampaign } from '@utils/api/campaigns/getCampaign';
import { updateCampaign } from '@utils/api/campaigns/updateCampaign';
import { deleteCampaign } from '@utils/api/campaigns/deleteCampaign';
import { deleteCodes } from '@utils/api/codes/deleteCodes';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';

export const useCampaignStore = create<CampaignsStore>((set, get) => ({
  ...DEFAULT_CAMPAIGN_STATE,

  getCampaign: async (campaignRef: DocumentReference) => {
    try {
      set({ loading: true });
      const campaign = await getCampaign(campaignRef);
      set({ campaign, loading: false });
    } catch (error) {
      set({ loading: false });
      console.error('Error getting campaign: ', error);
    }
  },
  getCampaignsByBookmaker: async (bookmakerRef: DocumentReference) => {
    try {
      set({ loading: true });
      const campaigns = await getCampaignsByBookmaker(bookmakerRef);
      set({ campaigns, loading: false });
    } catch (error) {
      set({ loading: false });
      console.error('Error getting campaigns: ', error);
    }
  },
  addCampaign: async (campaign: Campaign) => {
    try {
      return await addCampaign(campaign);
    } catch (error) {
      console.error('Error adding campaign: ', error);
      throw error;
    }
  },
  updateCampaign: async (campaignRef: DocumentReference, data: object) => {
    try {
      set({ loading: true });
      await updateCampaign(campaignRef, data);
      get().getCampaign(campaignRef);

      set({ loading: false });
    } catch (error) {
      console.error('Error updating campaign: ', error);
    }
  },
  deleteCampaign: async (campaignRef: DocumentReference) => {
    try {
      set({ loading: true });
      await deleteCampaign(campaignRef);
      await deleteCodes(campaignRef);

      const selectedBookmaker = useBookmakerStore.getState().selectedBookmaker;
      if (selectedBookmaker) {
        get().getCampaignsByBookmaker(selectedBookmaker.ref);
      }
      set({ loading: false });
    } catch (error) {
      console.error('Error deleting campaign: ', error);
    }
  },
  setCampaign: (campaign: Partial<Campaign>) =>
    set((state) => ({
      campaign: { ...state.campaign, ...campaign },
    })),
  resetCampaignStore: () => set({ ...DEFAULT_CAMPAIGN_STATE }),
}));
