import React, { useEffect } from 'react';
import TitleSection from '@components/TitleSection';
import { useUserStore } from '@store/useUserStore/useUserStore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { changeFormatDate, parseTimestamp } from '@utils/helpers';
import CustomLink from '@components/CustomLink';

export const ListUsers = () => {
  const { loading, users, getUsers } = useUserStore();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <TitleSection text="Lista użytkowników" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            loading={loading}
            disableColumnSelector
            disableColumnFilter
            disableRowSelectionOnClick
            disableDensitySelector
            sx={{ backgroundColor: 'white', height: 700 }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: {
                  fileName: 'Lista użytkowników',
                },
              },
            }}
            initialState={{
              sorting: { sortModel: [{ field: 'createdAt', sort: 'desc' }] },
            }}
            columns={[
              {
                field: 'createdAt',
                headerName: 'Data dołączenia',
                width: 240,
                disableColumnMenu: true,
                valueGetter: (value) =>
                  parseTimestamp(value, 'yyyy-MM-dd HH:mm'),
                renderCell: (params) =>
                  changeFormatDate(params.value, 'd MMMM, yyyy - HH:mm'),
              },
              {
                field: 'name',
                headerName: 'Imie i nazwisko',
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const id = params.row.ref?.id;
                  return id ? (
                    <CustomLink to={id} label={params.value} />
                  ) : (
                    <span>{params.value}</span>
                  );
                },
              },
              {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                disableColumnMenu: true,
              },
              {
                field: 'phoneNumber',
                headerName: 'Numer telefonu',
                flex: 1,
                disableColumnMenu: true,
              },
              {
                field: 'id',
                headerName: 'ID użytkownika',
                width: 300,
                disableColumnMenu: true,
              },
            ]}
            rows={users}
          />
        </Grid>
      </Grid>
    </>
  );
};
