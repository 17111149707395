import React, { useEffect, useState } from 'react';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { useFormik } from 'formik';
import Dialog from '@components/Dialog';
import Box from '@mui/material/Box';
import Input from '@components/Input';
import DatePicker from '@components/DatePicker';
import { removeTrailingSlash } from '@utils/helpers';
import Select from '@components/Select';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import { Timestamp } from 'firebase/firestore';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import useNotifier from '@utils/useNotifier';
import { successMessages } from '@messages/successMessages';

interface Props {
  dialogId: string;
}

const DialogEditCampaign: React.FC<Props> = ({ dialogId }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { notify } = useNotifier();
  const { closeDialog, dialogs } = useDialogState();
  const { getCampaign, campaign, updateCampaign, getCampaignsByBookmaker } =
    useCampaignStore();
  const { getBookmaker, bookmaker, loading } = useBookmakerStore();

  const dialogParams = dialogs[dialogId]?.params;

  useEffect(() => {
    if (dialogParams) {
      getCampaign(dialogParams.campaignRef);
    }
  }, [dialogParams]);

  useEffect(() => {
    if (campaign?.endAt instanceof Timestamp) {
      setSelectedDate(campaign.endAt.toDate());
    } else if (campaign?.endAt instanceof Date) {
      setSelectedDate(campaign.endAt);
    }

    if (campaign.bookmakerRef) {
      getBookmaker(campaign.bookmakerRef);
      getCampaignsByBookmaker(campaign.bookmakerRef);
    }
  }, [campaign]);

  const formik = useFormik({
    initialValues: {
      name: campaign.name,
      endAt: selectedDate,
      refLink: campaign.refLink,
      variant: campaign.variant,
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const newRefLink = removeTrailingSlash(values.refLink);

      updateCampaign(dialogParams.campaignRef, {
        bookmakerRef: campaign.bookmakerRef,
        name: values.name,
        endAt: values.endAt,
        variant: values.variant,
        refLink: newRefLink,
      });

      notify(successMessages.editCampaign, 'success');

      resetForm();
      closeDialog();
    },
  });

  return dialogs[dialogId]?.open ? (
    <Dialog
      id={dialogId}
      confirmName="Zapisz"
      confirmAction={formik.handleSubmit}
      closeName="Anuluj"
      closeAction={() => {
        formik.resetForm();
        closeDialog();
      }}
      width={500}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'wrap column',
          gap: 2,
          padding: 1,
        }}
      >
        <Input
          name="name"
          label="Nazwa kampanii"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <DatePicker
          label="Data zakończenia kampanii"
          name="endAt"
          value={formik.values.endAt}
          onChange={(value) => {
            formik.setFieldValue('endAt', value);
          }}
        />
        <Input
          name="refLink"
          label="Reflink"
          value={formik.values.refLink}
          onChange={(e) => {
            formik.setFieldValue('refLink', e.target.value);
          }}
        />
        <Select
          loading={loading}
          name="variant"
          label="Wybierz wariant"
          value={formik.values.variant}
          onChange={formik.handleChange}
          options={bookmaker.variants || []}
        />
      </Box>
    </Dialog>
  ) : null;
};

export default DialogEditCampaign;
