import { create } from 'zustand';
import { UserBookmakerStore } from '@store/useUserBookmakerStore/interfaces';
import { DEFAULT_USER_BOOKMAKER_STATE } from '@store/useUserBookmakerStore/consts';
import { getUserBookmakerByUserRef } from '@utils/api/userBookmakers/getUserBookmakerByUserRef';
import { getBookmaker } from '@utils/api/bookmakers/getBookmaker';
import { getUserCampaignsByUserBookmaker } from '@utils/api/userCampaigns/getUserCampaignsByUserBookmaker';
import { getCampaign } from '@utils/api/campaigns/getCampaign';

export const useUserBookmakerStore = create<UserBookmakerStore>((set) => ({
  ...DEFAULT_USER_BOOKMAKER_STATE,

  getUserBookmakers: async (userRef) => {
    try {
      const userBookmakers = await getUserBookmakerByUserRef(userRef);

      const updatedBookmakers = await Promise.all(
        userBookmakers.map(async (item) => {
          const bookmakerDetails = await getBookmaker(item.bookmakerRef);
          const userCampaigns = await getUserCampaignsByUserBookmaker(item.ref);

          const updatedUserCampaigns = await Promise.all(
            userCampaigns.map(async (userCampaign) => ({
              variantID: userCampaign.variantID,
              code: userCampaign.code,
              pending: userCampaign.pending,
              verifiedDate: userCampaign.verifiedDate,
              campaignRef: userCampaign.campaignRef,
              campaign: await getCampaign(userCampaign.campaignRef),
            })),
          );

          return {
            campaigns: [...updatedUserCampaigns],
            image: bookmakerDetails.image,
            name: bookmakerDetails.name,
            verified: item.verified,
          };
        }),
      );

      set({ userBookmakers: updatedBookmakers });
    } catch (error) {
      console.error('Błąd pobierania danych userBookmaker:', error);
    }
  },
}));
