import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import Grid from '@mui/material/Grid';
import Input from '@components/Input';
import Select from '@components/Select';
import Textarea from '@components/Textarea';
import TitleSection from '@components/TitleSection';
import { SelectChangeEvent } from '@mui/material/Select';
import { getBookmakerRef } from '@utils/api/bookmakers/getBookmakerRef';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import { useCodeStore } from '@store/useCodeStore/useCodeStore';
import useNotifier from '@utils/useNotifier';
import { useFormik } from 'formik';
import Card from '@mui/material/Card';
import Form from '@components/Form';
import { isEmptyObject, parseCodes, removeTrailingSlash } from '@utils/helpers';
import FormAlert from '@components/FormAlert';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { validationSchemaAddCampaign } from '@src/validationSchema/validations';
import { successMessages } from '@messages/successMessages';
import DatePicker from '@components/DatePicker';

interface FormValues {
  name: string;
  refLink: string;
  variant: string;
  codes: string;
  endAt: Date | null;
}

export const AddCampaign = () => {
  const { notify } = useNotifier();
  const [isBookmakerSelected, setIsBookmakerSelected] = useState(false);

  const { bookmakers, bookmaker, getBookmakers, getBookmaker } =
    useBookmakerStore();
  const { campaign, addCampaign, setCampaign } = useCampaignStore();
  const { addCodes } = useCodeStore();

  useEffect(() => {
    getBookmakers();
  }, [getBookmakers]);

  const handleSelectBookmaker = (e: SelectChangeEvent) => {
    setIsBookmakerSelected(true);
    const bookmakerRef = getBookmakerRef(e.target.value);
    getBookmaker(bookmakerRef);
    setCampaign({ bookmakerRef });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      refLink: '',
      variant: '',
      codes: '',
      endAt: null,
    },
    validationSchema: validationSchemaAddCampaign,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      const newRefLink = removeTrailingSlash(values.refLink);

      const updatedCampaign = {
        ...campaign,
        name: values.name,
        refLink: newRefLink,
        variant: values.variant,
        endAt: values.endAt,
      };
      const campaignRef = await addCampaign(updatedCampaign);

      if (values.codes) {
        const codes = parseCodes(values.codes);
        await addCodes(campaignRef, codes);
      }

      notify(successMessages.addCampaign, 'success');
      resetForm();
    },
  });

  return (
    <>
      <TitleSection text="Dodaj kampanie" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {!isEmptyObject(formik.errors) && (
                    <Grid item xs={12}>
                      <FormAlert errors={formik.errors} />
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    <Select
                      value={campaign?.bookmakerRef?.id || ''}
                      label="Wybierz bukmachera"
                      onChange={handleSelectBookmaker}
                      options={bookmakers.map((bookmaker) => ({
                        value: bookmaker.id,
                        label: bookmaker.name,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      disabled={!isBookmakerSelected}
                      label="Nazwa kampanii"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.name)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      label="Data zakończenia kampanii"
                      disabled={!isBookmakerSelected}
                      name="endAt"
                      error={Boolean(formik.errors.endAt)}
                      value={formik.values.endAt}
                      onChange={(value) => {
                        formik.setFieldValue('endAt', value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Input
                      disabled={!isBookmakerSelected}
                      label="Reflink"
                      name="refLink"
                      value={formik.values.refLink}
                      error={Boolean(formik.errors.refLink)}
                      onChange={(e) => {
                        formik.setFieldValue('refLink', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      disabled={!isBookmakerSelected}
                      label="Wybierz wariant"
                      name="variant"
                      error={Boolean(formik.errors.variant)}
                      value={formik.values.variant}
                      onChange={formik.handleChange}
                      options={bookmaker.variants || []}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textarea
                      disabled={!isBookmakerSelected}
                      label="Dodaj kod(y)"
                      name="codes"
                      error={Boolean(formik.errors.codes)}
                      value={formik.values.codes}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions sx={{ padding: 2 }}>
                <Button
                  disabled={!isBookmakerSelected}
                  type="submit"
                  text="Dodaj kampanie"
                />
              </CardActions>
            </Card>
          </Form>
        </Grid>
      </Grid>
    </>
  );
};
