import React from 'react';
import Default from '@src/layouts/default';
import { LayoutComponentsInterface } from '@store/useSettingStore/interfaces';
import { LayoutDefaultInterface } from '@src/layouts/interfaces';
import { layoutComponents } from '@enums/layouts';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';

const LayoutSwitcher: React.FC<LayoutDefaultInterface> = ({
  children,
  layout,
}) => {
  const { layouts } = useSettingStore();

  const mapLayouts = layouts.reduce((acc, layout) => {
    const componentName = layout.component;
    if (layoutComponents[componentName]) {
      acc[layout.id] = layoutComponents[componentName];
    }
    return acc;
  }, {} as LayoutComponentsInterface);

  const SelectedLayout =
    layout && mapLayouts[layout] ? mapLayouts[layout] : Default;

  return <SelectedLayout>{children}</SelectedLayout>;
};

export default LayoutSwitcher;
