import React from 'react';
import LayoutSwitcher from '@src/layouts';
import { useParams } from 'react-router-dom';
import DialogAlert from '@components/DialogAlert';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const { REACT_APP_RECAPTCHA_KEY } = process.env;
if (!REACT_APP_RECAPTCHA_KEY) {
  throw new Error('REACT_APP_RECAPTCHA_KEY is not defined');
}

export const Home: React.FC = () => {
  const params = useParams();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_KEY}>
      <LayoutSwitcher layout={params.layout}>
        <DialogAlert />
      </LayoutSwitcher>
    </GoogleReCaptchaProvider>
  );
};
