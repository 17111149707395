import { AdminPanelRoutes, Routes } from '@enums/routes';

export const MENU_DASHBOARD = [
  {
    path: Routes.ADMIN_PANEL,
    name: 'Przegląd',
    icon: 'SpaceDashboard',
  },
];

export const MENU_CAMPAIGN = [
  {
    path: AdminPanelRoutes.ADD_CAMPAIGN,
    name: 'Dodaj kampanie',
    icon: 'Campaign',
  },
  {
    path: AdminPanelRoutes.LIST_CAMPAIGNS,
    name: 'Lista kampanii',
    icon: 'Dvr',
  },
  {
    path: AdminPanelRoutes.LINK_GENERATOR,
    name: 'Generator linków',
    icon: 'AddLink',
  },
];

export const MENU_BOOKMAKER = [
  {
    path: AdminPanelRoutes.ADD_BOOKMAKER,
    name: 'Dodaj bukmachera',
    icon: 'PersonAdd',
  },
  {
    path: AdminPanelRoutes.LIST_BOOKMAKER,
    name: 'Lista bukmacherów',
    icon: 'Group',
  },
];

export const MENU_USER = [
  {
    path: AdminPanelRoutes.LIST_USERS,
    name: 'Lista użytkowników',
    icon: 'Group',
  },
];
