import React from 'react';
import { LayoutDefaultInterface } from '@src/layouts/interfaces';
import Container from '@mui/material/Container';
import Typography from '@components/Typography';
import Grid from '@mui/material/Grid';
import LayoutRegisterFormSwitcher from '@src/layouts/RegisterForm';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Routes } from '@enums/routes';
import Box from '@mui/material/Box';

const LayoutDefault: React.FC<LayoutDefaultInterface> = ({ children }) => {
  const params = useParams();
  const path = '/' + params.locale + Routes.PRIVACY_POLICY;

  return (
    <Container>
      <Grid container spacing={2} sx={{ marginTop: 3, marginBottom: 5 }}>
        <Grid item xs={4}>
          <Typography text="Title 1" variant="h4" />
          <Typography text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        </Grid>
        <Grid item xs={4}>
          <Typography text="Title 1" variant="h4" />
          <Typography text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        </Grid>
        <Grid item xs={4}>
          <Typography text="Title 1" variant="h4" />
          <Typography text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        </Grid>
      </Grid>

      <LayoutRegisterFormSwitcher layoutName="default" />

      {children}

      <Box sx={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
        <Link to={path}>Polityka prywatności</Link>
      </Box>
    </Container>
  );
};

export default LayoutDefault;
