import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';
import BookmakerFunction from '@components/BookmakerFunction';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Grid from '@mui/material/Grid';
import Input from '@components/Input';
import Typography from '@components/Typography';
import useSuperAdminCheck from '@utils/useSuperAdminCheck';
import { isEmptyObject } from '@utils/helpers';
import FormAlert from '@components/FormAlert';
import { validationSchemaFortunaPL } from '@src/validationSchema/validations';

export const Functions = () => {
  const { checkSuperAdmin } = useSuperAdminCheck();
  const {
    getFortunaPLSettings,
    forceGetUsersFromFortunaBookmaker,
    updateBookmakerSettings,
    fortunaPL,
    loading,
    loadingUpdate,
  } = useSettingStore();

  useEffect(() => {
    if (!loadingUpdate) {
      getFortunaPLSettings();
    }
  }, [loadingUpdate]);

  const fortunaPlFormik = useFormik({
    initialValues: {
      sport_rfd_amt: fortunaPL.sport_rfd_amt,
      sport_rfd_cnt: fortunaPL.sport_rfd_cnt,
      total_deposit_amt: fortunaPL.total_deposit_amt,
      total_deposit_cnt: fortunaPL.total_deposit_cnt,
      daysInterval: fortunaPL.daysInterval,
    },
    enableReinitialize: true,
    validationSchema: validationSchemaFortunaPL,
    onSubmit: (values, { resetForm }) => {
      const updateValues = {
        sport_rfd_amt: values.sport_rfd_amt.toString(),
        sport_rfd_cnt: values.sport_rfd_cnt.toString(),
        total_deposit_amt: values.total_deposit_amt.toString(),
        total_deposit_cnt: values.total_deposit_cnt.toString(),
        daysInterval: Number(values.daysInterval),
      };

      checkSuperAdmin(() => {
        if (fortunaPL.docRef) {
          updateBookmakerSettings(fortunaPL.docRef, updateValues);
        }
      });

      resetForm();
    },
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography text="Uruchom funkcje zdalnie" variant="h5" />
      </Grid>
      <Grid item xs={12}>
        <BookmakerFunction
          bookmakerRef={fortunaPL.bookmakerRef}
          loading={loading}
          handleStart={() =>
            checkSuperAdmin(() => forceGetUsersFromFortunaBookmaker())
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isEmptyObject(fortunaPlFormik.errors) && (
                <Grid item xs={12}>
                  <FormAlert errors={fortunaPlFormik.errors} />
                </Grid>
              )}
            </Grid>
            <Grid item xs={2}>
              <Input
                type="number"
                name="sport_rfd_amt"
                label="sport_rfd_amt"
                value={fortunaPlFormik.values.sport_rfd_amt}
                size="small"
                onChange={fortunaPlFormik.handleChange}
                inputProps={{ step: 0.001 }}
                error={Boolean(fortunaPlFormik.errors.sport_rfd_amt)}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                type="number"
                name="sport_rfd_cnt"
                label="sport_rfd_cnt"
                value={fortunaPlFormik.values.sport_rfd_cnt}
                size="small"
                onChange={fortunaPlFormik.handleChange}
                inputProps={{ step: 1 }}
                error={Boolean(fortunaPlFormik.errors.sport_rfd_cnt)}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                type="number"
                name="total_deposit_amt"
                label="total_deposit_amt"
                value={fortunaPlFormik.values.total_deposit_amt}
                size="small"
                onChange={fortunaPlFormik.handleChange}
                inputProps={{ step: 0.001 }}
                error={Boolean(fortunaPlFormik.errors.total_deposit_amt)}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                type="number"
                name="total_deposit_cnt"
                label="total_deposit_cnt"
                value={fortunaPlFormik.values.total_deposit_cnt}
                size="small"
                onChange={fortunaPlFormik.handleChange}
                inputProps={{ step: 1 }}
                error={Boolean(fortunaPlFormik.errors.total_deposit_cnt)}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                type="number"
                name="daysInterval"
                label="Ilość dni"
                value={fortunaPlFormik.values.daysInterval}
                size="small"
                onChange={fortunaPlFormik.handleChange}
                inputProps={{ step: 1 }}
                error={Boolean(fortunaPlFormik.errors.daysInterval)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
              >
                <Button
                  text="Zapisz"
                  size="small"
                  loading={loadingUpdate}
                  onClick={fortunaPlFormik.handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </BookmakerFunction>
      </Grid>
    </Grid>
  );
};
