import React from 'react';
import { Outlet } from 'react-router-dom';
import LayoutAdmin from '@components/LayoutAdmin';

export const AdminPanel = () => {
  return (
    <LayoutAdmin>
      <Outlet />
    </LayoutAdmin>
  );
};
