export const translationCZ = {
  translation: {
    register: 'Registrace',
    hi: 'Ahoj!',
    lorem:
      'Lorem Ipsum je demonstrativní výplňový text používaný v tiskařském a knihařském průmyslu. Lorem Ipsum je považováno za standard v této oblasti už od začátku 16. století, kdy dnes neznámý tiskař vzal kusy textu a na jejich základě vytvořil speciální vzorovou knihu. Jeho odkaz nevydržel pouze pět století, on přežil i nástup elektronické sazby v podstatě beze změny. Nejvíce popularizováno bylo Lorem Ipsum v šedesátých letech 20. století, kdy byly vydávány speciální vzorníky s jeho pasážemi a později pak díky počítačovým DTP programům jako Aldus PageMaker.',
    loremShort:
      'Lorem Ipsum je demonstrativní výplňový text používaný v tiskařském a knihařském průmyslu. Lorem Ipsum je považováno za standard v této oblasti už od začátku 16.',
    fullName: 'Jméno a příjmení:',
    phoneNumber: 'Telefonní číslo:',
    email: 'Email',
    password: 'Heslo',
    agree1: 'Souhlasím s…',
    agree2: 'Souhlasím s účastí v loterii. Více informací na: example.pl',
    signUp: 'Zaregistrujte se',
    logIn: 'Přihlásit se',
    homePage: 'Domovská stránka',
    login: 'Přihlášení',
    logOut: 'Odhlásit se',
    quote1:
      'Programy musí být psány pro lidi, aby je četli, a jen okrajově pro stroje, aby je vykonávaly. – Harold Abelson',
    quote2:
      'Každý hlupák může napsat kód, který počítač pochopí. Dobrý programátor píše kód, kterému rozumí lidé. – Martin Fowler',
    quote3:
      'Kód je jako humor. Když ho musíš vysvětlovat, je špatný. – Cory House',
    quote4: 'Nejdřív vyřeš problém. Potom napiš kód. – John Johnson',
    quote5: 'Jednoduchost je duší efektivity. – Austin Freeman',
    quote6:
      'Programování není o tom, co víš; jde o to, co dokážeš vymyslet. – Chris Pine',
    quote7:
      'Nejlepší chybová zpráva je ta, která se nikdy neobjeví. – Thomas Fuchs',
    quote8:
      'Nejdůležitější vlastností programu je, zda dosáhne záměru svého uživatele. – C.A.R. Hoare',
    privacy_policy_0:
      'Tato Zásady ochrany osobních údajů popisují pravidla zpracování osobních údajů společností [Vaše Firma] se sídlem v [adresa sídla], zapsanou v rejstříku [název rejstříku] pod číslem [číslo rejstříku].',
    privacy_policy_1: '1. Správce osobních údajů',
    privacy_policy_1_1:
      'Správcem vašich osobních údajů je [Vaše Firma] se sídlem v [adresa sídla]. Můžete nás kontaktovat e-mailem: [e-mailová adresa] nebo telefonicky: [telefonní číslo].',
    privacy_policy_2: '2. Účely a právní základ zpracování údajů',
    privacy_policy_2_1: 'Vaše osobní údaje zpracováváme pro následující účely:',
    privacy_policy_2_1_1:
      '- Poskytování služeb: Zpracování údajů je nezbytné pro plnění smlouvy, jejíž jste stranou, nebo pro provedení opatření na vaši žádost před uzavřením smlouvy (čl. 6 odst. 1 písm. b GDPR).',
    privacy_policy_2_1_2:
      '- Marketing: Za účelem zasílání marketingových informací s vaším souhlasem (čl. 6 odst. 1 písm. a GDPR).',
    privacy_policy_2_1_3:
      '- Splnění právních povinností: Zpracováváme údaje za účelem plnění právních povinností, například v souvislosti s účetnictvím (čl. 6 odst. 1 písm. c GDPR).',
    privacy_policy_2_1_4:
      '- Oprávněné zájmy: Za účelem provádění analýz, statistik a ochrany před nároky (čl. 6 odst. 1 písm. f GDPR).',
    privacy_policy_3: '3. Druhy zpracovávaných osobních údajů',
    privacy_policy_3_1: 'Zpracováváme následující kategorie osobních údajů:',
    privacy_policy_3_1_1: '- Identifikační údaje (např. jméno a příjmení)',
    privacy_policy_3_1_2:
      '- Kontaktní údaje (např. e-mailová adresa, telefonní číslo)',
    privacy_policy_3_1_3:
      '- Údaje související s plněním smlouvy (např. historie objednávek)',
    privacy_policy_4: '4. Příjemci osobních údajů',
    privacy_policy_4_1:
      'Vaše osobní údaje mohou být předávány následujícím kategoriím příjemců:',
    privacy_policy_4_1_1:
      '- Subjekty zpracovávající údaje na základě našeho pověření (např. IT společnosti, účetní firmy)',
    privacy_policy_4_1_2: '- Veřejné orgány, pokud to vyžaduje platný zákon',
    privacy_policy_4_1_3:
      '- Další subjekty spolupracující na základě smlouvy s [Vaše Firma]',
    privacy_policy_5: '5. Přenos údajů mimo EHP',
    privacy_policy_5_1:
      'Vaše osobní údaje nebudou přenášeny mimo Evropský hospodářský prostor (EHP), pokud to nebude nezbytné a nezajistíme odpovídající úroveň ochrany údajů.',
    privacy_policy_6: '6. Doba uchovávání údajů',
    privacy_policy_6_1:
      'Osobní údaje uchováváme po dobu nezbytnou k dosažení účelů zpracování nebo do odvolání souhlasu, pokud je zpracování prováděno na jeho základě. Tato doba může být prodloužena o dobu nezbytnou k ochraně nebo uplatnění případných nároků.',
    privacy_policy_7: '7. Vaše práva',
    privacy_policy_7_1:
      'V souvislosti se zpracováním vašich osobních údajů máte následující práva:',
    privacy_policy_7_1_1: '- Právo na přístup k údajům',
    privacy_policy_7_1_2: '- Právo na opravu údajů',
    privacy_policy_7_1_3: '- Právo na výmaz údajů („právo být zapomenut“) ',
    privacy_policy_7_1_4: '- Právo na omezení zpracování',
    privacy_policy_7_1_5: '- Právo na přenositelnost údajů',
    privacy_policy_7_1_6: '- Právo vznést námitku proti zpracování',
    privacy_policy_7_1_7: '- Právo odvolat souhlas',
    privacy_policy_7_2:
      'Chcete-li uplatnit svá práva, kontaktujte nás na e-mailové adrese: [e-mailová adresa].',
    privacy_policy_8: '8. Bezpečnost údajů',
    privacy_policy_8_1:
      'Používáme vhodná technická a organizační opatření k ochraně vašich osobních údajů před neoprávněným přístupem, ztrátou, zničením nebo změnou.',
    privacy_policy_9: '9. Změny v Zásadách ochrany osobních údajů',
    privacy_policy_9_1:
      'Vyhrazujeme si právo provádět změny těchto Zásad ochrany osobních údajů. O jakýchkoli změnách vás budeme informovat zveřejněním nové verze zásad na našich webových stránkách.',
    privacy_policy_10: '10. Kontakt',
    privacy_policy_10_1:
      'V případě dotazů týkajících se zpracování vašich osobních údajů nás prosím kontaktujte na e-mailové adrese: [e-mailová adresa] nebo telefonicky: [telefonní číslo].',
    privacy_policy_10_2:
      'Nezapomeňte, že je třeba tento dokument doplnit o podrobnosti specifické pro vaši činnost a před zveřejněním získat odborné právní poradenství.',
    user_creation_error: 'Chyba',
    campaign_not_found: 'Kampaň neexistuje!',
    campaign_inactive: 'Kampaň je neaktivní.',
    account_already_exists: 'Bohužel u tohoto bookmakera již máte účet.',
  },
};
