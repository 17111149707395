import { create } from 'zustand';
import { AuthStore } from '@store/useAuthStore/interfaces';
import { DEFAULT_AUTH_STATE } from '@store/useAuthStore/consts';
import { Collection } from '@enums/collection';
import {
  auth,
  db,
  doc,
  signOut,
  getDoc,
  onAuthStateChanged,
} from '@src/firebase-config';
import { Routes } from '@enums/routes';
import { signInUser } from '@utils/api/users/signInUser';
import { getUserCountry } from '@utils/helpers';

export const useAuthStore = create<AuthStore>((set) => ({
  ...DEFAULT_AUTH_STATE,

  signOut: async () => {
    await signOut(auth);
    set({ user: null, role: '' });
  },
  signIn: async (navigate) => {
    set({ loading: true });

    try {
      const user = await signInUser();

      if (user && user.uid) {
        const userDoc = await getDoc(doc(db, Collection.USERS, user.uid));
        if (userDoc.exists()) {
          set({
            role: userDoc.data()?.role,
            superAdmin: userDoc.data()?.superAdmin,
            user: user,
            loading: false,
          });
          navigate(Routes.ADMIN_PANEL);
          return;
        }
      }
    } catch (error) {
      set({
        role: '',
        superAdmin: false,
        user: null,
        loading: false,
      });

      throw error;
    }
  },
  fetchUser: async () => {
    set({ loading: true });

    const country = await getUserCountry();
    set({ language: country });

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, Collection.USERS, user.uid));
        if (userDoc.exists()) {
          set({
            role: userDoc.data()?.role,
            superAdmin: userDoc.data()?.superAdmin,
          });
        }
        set({ user });
      } else {
        set({ user: null, role: '', superAdmin: false });
      }
      set({ loading: false, initialized: true });
    });
  },

  setLoading: (loading) => set(() => ({ loading })),
  setField: (field, value) =>
    set((state) => ({
      ...state,
      [field]: value,
    })),
  resetFields: () => set(() => DEFAULT_AUTH_STATE),
}));
