import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminPanelRoutes } from '@enums/routes';
import { headerStyles } from '@components/HeaderAdmin/styles';
import Box from '@mui/material/Box';
import ButtonIcon from '@components/ButtonIcon';
import Button from '@components/Button';
import { useAuthStore } from '@store/useAuthStore/useAuthStore';

const HeaderAdmin = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthStore();

  return (
    <Box sx={headerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          gap: '12px',
          marginLeft: 'auto',
        }}
      >
        <ButtonIcon
          onClick={() => navigate(AdminPanelRoutes.SETTINGS)}
          icon="Settings"
          color="primary"
        />
        <Button
          variant="outlined"
          text="Wyloguj"
          startIcon="Logout"
          color="error"
          onClick={() => signOut()}
        />
      </Box>
    </Box>
  );
};

export default HeaderAdmin;
