import React from 'react';
import Form from '@components/Form';
import CardContent from '@mui/material/CardContent';
import { isEmptyObject } from '@utils/helpers';
import FormAlert from '@components/FormAlert';
import Input from '@components/Input';
import { MuiTelInput } from 'mui-tel-input';
import Checkbox from '@components/Checkbox';
import CardActions from '@mui/material/CardActions';
import Button from '@components/Button';
import Card from '@mui/material/Card';
import { useAuthStore } from '@store/useAuthStore/useAuthStore';
import { useRegisterStore } from '@store/useRegisterStore/useRegisterStore';
import { LayoutRegisterForm } from '@src/layouts/RegisterForm/interfaces';
import { useTranslation } from 'react-i18next';

const RegisterFormDefault = ({ formik }: LayoutRegisterForm) => {
  const { t } = useTranslation();
  const { language } = useAuthStore();
  const registerState = useRegisterStore.getState();

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit}>
        <CardContent sx={{ display: 'flex', flexFlow: 'wrap column', gap: 2 }}>
          {!isEmptyObject(formik.errors) && (
            <FormAlert errors={formik.errors} />
          )}
          <Input
            label={t('fullName')}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
          />
          <MuiTelInput
            label={t('phoneNumber')}
            name="phoneNumber"
            value={formik.values.phoneNumber}
            defaultCountry={language}
            error={Boolean(formik.errors.phoneNumber)}
            onChange={(value) => {
              formik.setFieldValue('phoneNumber', value);
            }}
          />
          <Input
            label={t('email')}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email)}
          />
          <Checkbox
            error={Boolean(formik.errors.agreement_1)}
            label={t('agree1')}
            name="agreement_1"
            checked={formik.values.agreement_1}
            onChange={formik.handleChange}
          />
          <Checkbox
            error={Boolean(formik.errors.agreement_2)}
            label={t('agree2')}
            name="agreement_2"
            checked={formik.values.agreement_2}
            onChange={formik.handleChange}
          />
        </CardContent>
        <CardActions sx={{ padding: 2, justifyContent: 'center' }}>
          <Button
            type="submit"
            loading={registerState.loading}
            text={t('signUp')}
          />
        </CardActions>
      </Form>
    </Card>
  );
};

export default RegisterFormDefault;
