import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Form from '@components/Form';
import Input from '@components/Input';
import Paper from '@mui/material/Paper';
import Typography from '@components/Typography';
import { Routes } from '@enums/routes';
import { useAuthStore } from '@store/useAuthStore/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmptyObject } from '@utils/helpers';
import FormAlert from '@components/FormAlert';
import { validationSchemaLogin } from '@src/validationSchema/validations';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const { REACT_APP_RECAPTCHA_KEY } = process.env;
if (!REACT_APP_RECAPTCHA_KEY) {
  throw new Error('REACT_APP_RECAPTCHA_KEY is not defined');
}

interface Props {
  t: (key: string) => string;
}

interface FormValues {
  email: string;
  password: string;
}

export const Login: React.FC<Props> = ({ t }) => {
  const navigate = useNavigate();
  const { setField, signIn, user, loading } = useAuthStore();

  useEffect(() => {
    if (user != null) {
      navigate(Routes.ADMIN_PANEL);
    }
  }, []);

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchemaLogin,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm, setFieldError }) => {
      try {
        setField('email', values.email);
        setField('password', values.password);
        await signIn(navigate);

        if (loading) {
          resetForm();
        }
      } catch (error) {
        setFieldError('general', 'Błędny adres email lub hasło');
      }
    },
  });

  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_KEY}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'wrap column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              width: '400px',
              flexFlow: 'wrap column',
              justifyContent: 'center',
              padding: 2,
              gap: 2,
            }}
          >
            <Typography
              text={t('login')}
              variant="h4"
              align="center"
              gutterBottom
            />

            {!isEmptyObject(formik.errors) && (
              <FormAlert errors={formik.errors} />
            )}

            <Input
              label={t('email')}
              name="email"
              value={formik.values.email}
              error={Boolean(formik.errors.email)}
              onChange={formik.handleChange}
            />
            <Input
              label={t('password')}
              type="password"
              name="password"
              error={Boolean(formik.errors.password)}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <Button type="submit" text={t('logIn')} loading={loading} />
          </Paper>
        </Form>
      </Box>
    </GoogleReCaptchaProvider>
  );
};
