import React from 'react';
import { LayoutDefaultInterface } from '@src/layouts/interfaces';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LayoutRegisterFormSwitcher from '@src/layouts/RegisterForm';

const LayoutLayout1: React.FC<LayoutDefaultInterface> = ({ children }) => {
  return (
    <Container>
      <Grid container spacing={2} sx={{ marginTop: 3, marginBottom: 5 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              display: 'block',
              borderRadius: 2,
            }}
            component="img"
            src="https://picsum.photos/1024/300"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography text="Title 1" variant="h4" />
          <Typography text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        </Grid>
        <Grid item xs={4}>
          <Typography text="Title 1" variant="h4" />
          <Typography text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        </Grid>
        <Grid item xs={4}>
          <Typography text="Title 1" variant="h4" />
          <Typography text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." />
        </Grid>
        <Grid item xs={12}>
          <Typography text="Title 1" variant="h4" align="center" />
          <Typography
            align="center"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
          />
        </Grid>
      </Grid>

      <LayoutRegisterFormSwitcher layoutName="default" />

      {children}

      <Box sx={{ marginBottom: 5, marginTop: 5 }}>Footer</Box>
    </Container>
  );
};

export default LayoutLayout1;
