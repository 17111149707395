import React from 'react';
import { FormInterface } from '@components/Form/intefaces';
import Box from '@mui/material/Box';

const Form: React.FC<FormInterface> = ({ onSubmit, children }) => (
  <Box component="form" onSubmit={onSubmit}>
    {children}
  </Box>
);

export default Form;
