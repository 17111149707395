import React from 'react';
import Box from '@mui/material/Box';
import { TabPanelInterface } from '@components/TabPanel/interfaces';

const TabPanel: React.FC<TabPanelInterface> = ({ children, value, index }) => {
  return (
    <Box hidden={value !== index} component="div" sx={{ padding: 2 }}>
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
