import React from 'react';
import { TextareaInterface } from '@components/Textarea/interfaces';
import TextField from '@mui/material/TextField';

const Textarea: React.FC<TextareaInterface> = ({
  value,
  onChange,
  label,
  disabled,
  name,
  error,
}) => {
  return (
    <TextField
      sx={{ backgroundColor: 'white' }}
      fullWidth
      multiline
      value={value}
      name={name}
      onChange={onChange}
      label={label}
      error={error}
      disabled={disabled}
      rows={6}
    />
  );
};

export default Textarea;
