export enum Collection {
  BOOKMAKERS = 'bookmakers',
  CAMPAIGNS = 'campaigns',
  CODES = 'codes',
  SETTINGS = 'settings',
  USERS = 'users',
  USER_BOOKMAKERS = 'userBookmakers',
  USER_CAMPAIGNS = 'userCampaigns',
  LAYOUTS = 'layouts',
  SETTINGS_BOOKMAKER = 'settingsBookmaker',
}

export enum SettingsBookmakerID {
  FORTUNA_PL = 'fortunaPL',
}
