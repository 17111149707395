import React from 'react';
import { ButtonIconInterface } from '@components/ButtonIcon/interfaces';
import IconButton from '@mui/material/IconButton';
import { renderIcon } from '@utils/renderIcon';

const ButtonIcon: React.FC<ButtonIconInterface> = ({
  onClick,
  icon,
  disabled,
  color,
  children,
}) => {
  return (
    <IconButton
      onClick={onClick}
      size="medium"
      disabled={disabled}
      sx={{ display: 'flex', opacity: disabled ? 0.3 : 1 }}
    >
      {renderIcon({ icon, color })} {children}
    </IconButton>
  );
};

export default ButtonIcon;
