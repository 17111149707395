import { create } from 'zustand';
import { db, collection, doc, writeBatch } from '@src/firebase-config';
import { DocumentReference } from '@firebase/firestore';
import { CodesStore, Code } from './interfaces';
import { DEFAULT_CODE_STATE, initialCode } from '@store/useCodeStore/consts';
import { Collection } from '@enums/collection';
import { StatusCode } from '@enums/status';
import { getCodesByCampaign } from '@utils/api/codes/getCodesByCampaign';

export const useCodeStore = create<CodesStore>((set) => ({
  ...DEFAULT_CODE_STATE,

  getCodesByCampaign: async (campaignRef: DocumentReference): Promise<void> => {
    try {
      set({ loading: true });
      const codes = await getCodesByCampaign(campaignRef);
      set({ codes, loading: false });
    } catch (error) {
      console.error('Error getting codes: ', error);
    }
  },
  addCodes: async (
    campaignRef: DocumentReference,
    codes: string[],
  ): Promise<void> => {
    try {
      const batch = writeBatch(db);
      codes.forEach((code) => {
        const codeRef = doc(collection(db, Collection.CODES));
        batch.set(codeRef, {
          campaignRef,
          code,
          status: StatusCode.AVAILABLE,
        });
      });
      await batch.commit();
    } catch (error) {
      console.error('Error adding codes: ', error);
    }
  },
  setCode: (code: Partial<Code>) =>
    set((state) => ({
      code: { ...state.code, ...code },
    })),
  clearCode: () => set({ code: initialCode }),

  setCodesInput: (value: string) => set({ codesInput: value }),
  clearCodesInput: () => set({ codesInput: '' }),
}));
