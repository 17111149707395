export const baseStyles = {
  position: 'relative',
  display: 'flex',
  flexFlow: 'wrap column',
  alignItems: 'center',
  width: '100vw',
  minHeight: '100vh',
  margin: '0',
  padding: '0',
};
