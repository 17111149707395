import React from 'react';
import { useFormik } from 'formik';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Grid from '@mui/material/Grid';
import Input from '@components/Input';
import Typography from '@components/Typography';
import useSuperAdminCheck from '@utils/useSuperAdminCheck';

export const Verifications = () => {
  const { checkSuperAdmin } = useSuperAdminCheck();
  const { loading, loadingSimulate, forceUserCodeAddition, simulateVerified } =
    useSettingStore();

  const formik = useFormik({
    initialValues: {
      codes: '',
    },
    onSubmit: (values, { resetForm }) => {
      checkSuperAdmin(() => {
        simulateVerified([values.codes]);
      });

      resetForm();
    },
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography text="Weryfikuj kod" variant="h5" />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Input
              name="codes"
              label="Podaj kod"
              value={formik.values.codes}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Button
                type="submit"
                text="Weryfikuj"
                loading={loadingSimulate}
                onClick={formik.handleSubmit}
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Button
                text="Uruchom kolejkę kodów"
                variant="outlined"
                loading={loading}
                onClick={() => checkSuperAdmin(() => forceUserCodeAddition())}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
