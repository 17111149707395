const errorMessages = {
  emailAlreadyInUse:
    'Podany adres e-mail jest już używany przez istniejącego użytkownika. Każdy użytkownik musi mieć unikalny adres e-mail.',
  registrationFailed: 'Wysąpił błąd rejestracji',
  weakPassword: 'Hasło powinno mieć przynajmniej 6 znaków',
  errorSingIn: 'Wystąpił błąd logowania',
  unknownError: 'Nieznany błąd',
  userBookmakerVerified: 'Niestety ale u tego bukmachera masz już konto.',
  notFoundCampaign: 'Nie znaleziono kampanii.',
  failedCopy: 'Nie udało się skopiowac do schowka.',
  endCampaign: 'Kampania dobiegła końca, nie można dodać kodów.',
  deleteCampaign:
    'Nie można usunć kampanii, poniważ już ktoś bierze w niej udział.',
  noSuperAdminPermissions: 'Nie posiadasz uprawnień superadministratora.',
};

const firebaseErrorMessages: Record<string, string> = {
  'auth/email-already-in-use': errorMessages.emailAlreadyInUse,
  'auth/weak-password': errorMessages.weakPassword,
  'auth/invalid-credential': errorMessages.errorSingIn,
  'auth/invalid-email': errorMessages.errorSingIn,
};

export { errorMessages, firebaseErrorMessages };
