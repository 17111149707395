import React, { useEffect, useState } from 'react';
import Select from '@components/Select';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import { getBookmakerRef } from '@utils/api/bookmakers/getBookmakerRef';
import Button from '@components/Button';
import { generateURL } from '@utils/helpers';
import { getCampaignRef } from '@utils/api/campaigns/getCampaignRef';
import TitleSection from '@components/TitleSection';
import useNotifier from '@utils/useNotifier';
import { useFormik } from 'formik';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Form from '@components/Form';
import { successMessages } from '@messages/successMessages';
import Grid from '@mui/material/Grid';
import CopyToClipboard from '@components/CopyToClipboard';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';
import { LANGUAGE_OPTIONS } from '@enums/languages';
import { Status } from '@enums/status';

export const LinkGenerator = () => {
  const { notify } = useNotifier();
  const navigate = useNavigate();
  const { layouts } = useSettingStore();
  const { bookmakers, getBookmakers } = useBookmakerStore();
  const { campaigns, getCampaignsByBookmaker, updateCampaign, loading } =
    useCampaignStore();

  const [generatedLink, setGeneratedLink] = useState<string>('');
  const [campaignID, setCampaignID] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      bookmaker: '',
      campaign: '',
      language: '',
      template: '',
    },
    onSubmit: async (values, { resetForm }) => {
      const link = generateURL(
        `/${values.language}/${values.campaign}/${values.template}`,
      );
      const campaignRef = getCampaignRef(values.campaign);
      updateCampaign(campaignRef, {
        link,
        template: values.template,
        lang: values.language,
      });

      setGeneratedLink(link);
      setCampaignID(values.campaign);
      notify(successMessages.addLink, 'success');
      resetForm();
    },
  });

  useEffect(() => {
    getBookmakers();
  }, [getBookmakers]);

  useEffect(() => {
    if (formik.values.bookmaker) {
      const bookmakerRef = getBookmakerRef(formik.values.bookmaker);
      getCampaignsByBookmaker(bookmakerRef);
    }
  }, [formik.values.bookmaker, getCampaignsByBookmaker]);

  return (
    <>
      <TitleSection text="Generator linków reklamowych" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <Form onSubmit={formik.handleSubmit}>
              <CardContent
                sx={{ display: 'flex', flexFlow: 'wrap column', gap: 2 }}
              >
                <Select
                  options={bookmakers.map((bookmaker) => ({
                    value: bookmaker.id,
                    label: bookmaker.name,
                  }))}
                  name="bookmaker"
                  value={formik.values.bookmaker}
                  onChange={({ target: { value, name } }) => {
                    formik.resetForm();
                    formik.setFieldValue(name, value);
                  }}
                  label="Wybierz bukmachera"
                />

                {Boolean(formik.values.bookmaker) && (
                  <Select
                    options={campaigns
                      .filter((campaign) => campaign.status === Status.ACTIVE)
                      .map((campaign) => ({
                        value: campaign.id,
                        label: campaign.name,
                      }))}
                    name="campaign"
                    value={formik.values.campaign}
                    onChange={formik.handleChange}
                    label="Wybierz kampanie"
                    disabled={Boolean(!formik.values.bookmaker)}
                  />
                )}

                {Boolean(formik.values.campaign) && (
                  <Select
                    options={LANGUAGE_OPTIONS}
                    name="language"
                    value={formik.values.language}
                    onChange={formik.handleChange}
                    label="Wybierz język"
                    disabled={Boolean(!formik.values.campaign)}
                  />
                )}

                {Boolean(formik.values.language) && (
                  <Select
                    options={layouts.map((layout) => ({
                      value: layout.id,
                      label: layout.name,
                    }))}
                    name="template"
                    value={formik.values.template}
                    onChange={formik.handleChange}
                    label="Wybierz template"
                    disabled={Boolean(!formik.values.language)}
                  />
                )}
              </CardContent>

              <CardActions sx={{ padding: 2 }}>
                <Button
                  loading={loading}
                  disabled={Boolean(!formik.values.language)}
                  text="Generuj link"
                  type="submit"
                />
              </CardActions>
            </Form>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {generatedLink && (
            <Card>
              <CardContent sx={{ padding: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography text="Link reklamowy" variant="caption" />
                    <Typography text={generatedLink} variant="h6" />
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <CopyToClipboard text={generatedLink} />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12}>
          {campaignID && (
            <Card>
              <CardContent sx={{ padding: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography text="Przejdź do kampanii" variant="caption" />
                    <Typography text={campaignID} variant="h6" />
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        text="Przejdź"
                        endIcon="NavigateNext"
                        variant="outlined"
                        onClick={() =>
                          navigate(`/admin-panel/list-campaigns/${campaignID}`)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};
