import React from 'react';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import NotInterested from '@mui/icons-material/NotInterested';

export const chipStatus = (status: string) => {
  switch (status) {
    case 'active':
      return (
        <Chip
          label="Aktywny"
          color="success"
          size="small"
          icon={<CheckIcon />}
        />
      );
    case 'inactive':
      return (
        <Chip
          label="Nieaktywny"
          color="error"
          size="small"
          icon={<NotInterested />}
        />
      );
    case 'available':
      return (
        <Chip
          label="Dostępny"
          color="success"
          size="small"
          icon={<CheckIcon />}
        />
      );
    case 'used':
      return (
        <Chip
          label="Użyty"
          color="error"
          size="small"
          icon={<NotInterested />}
        />
      );
    case 'true':
      return (
        <Chip
          label="Zweryfikowany"
          color="success"
          size="medium"
          icon={<CheckIcon />}
        />
      );
    case 'false':
      return (
        <Chip
          label="Niezweryfikowany"
          color="error"
          size="medium"
          icon={<NotInterested />}
        />
      );
    default:
      return null;
  }
};
