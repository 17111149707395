import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Form from '@components/Form';
import Grid from '@mui/material/Grid';
import Input from '@components/Input';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';
import useNotifier from '@utils/useNotifier';
import { successMessages } from '@messages/successMessages';
import ButtonIcon from '@components/ButtonIcon';
import { renderIcon } from '@utils/renderIcon';
import { validationSchemaAddLayout } from '@src/validationSchema/validations';
import { isEmptyObject } from '@utils/helpers';
import FormAlert from '@components/FormAlert';
import Typography from '@components/Typography';
import { DocumentReference } from '@firebase/firestore';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { DialogsID } from '@enums/dialogs';
import DialogDeleteConfirm from '@components/DialogDeleteConfirm';
import DialogEditLayout from '@components/DialogEditLayout';
import { getLayout } from '@utils/api/settings/getLayout';
import useSuperAdminCheck from '@utils/useSuperAdminCheck';

export const Layouts = () => {
  const { notify } = useNotifier();
  const { checkSuperAdmin } = useSuperAdminCheck();
  const { setDialog } = useDialogState();
  const { layouts, getLayouts, addLayout, deleteLayout, loading } =
    useSettingStore();

  useEffect(() => {
    getLayouts();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      component: '',
    },
    validationSchema: validationSchemaAddLayout,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      checkSuperAdmin(() => {
        addLayout(values.name, values.component);
        notify(successMessages.layoutAdded, 'success');
      });

      resetForm();
    },
  });

  const handleDeleteLayout = (layoutRef: DocumentReference) => {
    setDialog({
      id: DialogsID.DELETE_LAYOUT,
      title: 'Usuń szablon',
      text: 'Czy na pewno chcesz usunąć szablon?',
      action: () => {
        deleteLayout(layoutRef);
        notify(successMessages.layoutDelete, 'success');
      },
    });
  };

  const handleEditLayout = async (ref: DocumentReference) => {
    const layoutData = await getLayout(ref);

    setDialog({
      id: DialogsID.EDIT_LAYOUT,
      title: 'Edytuj szablon',
      params: { name: layoutData.name, component: layoutData.component, ref },
    });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography text="Dodaj szablon" variant="h5" />
        </Grid>
        <Grid item xs={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {!isEmptyObject(formik.errors) && (
                <Grid item xs={12}>
                  <FormAlert errors={formik.errors} />
                </Grid>
              )}
              <Grid item xs={5}>
                <Input
                  name="name"
                  label="Nazwa szablonu"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                />
              </Grid>
              <Grid item xs={5}>
                <Input
                  name="component"
                  label="Nazwa komponent"
                  value={formik.values.component}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.component)}
                />
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    text="Dodaj szablon"
                    loading={loading}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            disableColumnSelector
            disableColumnFilter
            disableRowSelectionOnClick
            disableDensitySelector
            sx={{ backgroundColor: 'white', height: 360 }}
            columns={[
              {
                field: 'id',
                headerName: 'ID',
                width: 230,
                disableColumnMenu: true,
              },
              {
                field: 'name',
                headerName: 'Nazwa szablonu',
                flex: 1,
                disableColumnMenu: true,
              },
              {
                field: 'component',
                headerName: 'Nazwa komponentu',
                flex: 1,
                disableColumnMenu: true,
              },
              {
                field: 'tools',
                renderHeader: () => renderIcon({ icon: 'Construction' }),
                headerAlign: 'center',
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 120,
                renderCell: (params) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      gap: 1,
                    }}
                  >
                    <ButtonIcon
                      icon="Edit"
                      onClick={() =>
                        checkSuperAdmin(() => handleEditLayout(params.row.ref))
                      }
                    />
                    <ButtonIcon
                      icon="Delete"
                      color="error"
                      onClick={() =>
                        checkSuperAdmin(() =>
                          handleDeleteLayout(params.row.ref),
                        )
                      }
                    />
                  </Box>
                ),
              },
            ]}
            rows={layouts}
          />
        </Grid>
      </Grid>

      <DialogEditLayout dialogId={DialogsID.EDIT_LAYOUT} />
      <DialogDeleteConfirm dialogId={DialogsID.DELETE_LAYOUT} />
    </>
  );
};
