import React from 'react';
import TitleSection from '@components/TitleSection';
import Typography from '@components/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <TitleSection
          text="Polityka prywatności"
          onClick={() => navigate(-1)}
        />

        <Typography text={t('privacy_policy_0')} variant="body1" gutterBottom />
        <Typography text={t('privacy_policy_1')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_1_1')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_2')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_2_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_2_1_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_2_1_2')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_2_1_3')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_2_1_4')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_3')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_3_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_3_1_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_3_1_2')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_3_1_3')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_4')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_4_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_4_1_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_4_1_2')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_4_1_3')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_5')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_5_1')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_6')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_6_1')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_7')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_7_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_2')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_3')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_4')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_5')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_6')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_1_7')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_7_2')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_8')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_8_1')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_9')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_9_1')}
          variant="body1"
          gutterBottom
        />

        <Typography text={t('privacy_policy_10')} variant="h5" gutterBottom />
        <Typography
          text={t('privacy_policy_10_1')}
          variant="body1"
          gutterBottom
        />
        <Typography
          text={t('privacy_policy_10_2')}
          variant="body1"
          gutterBottom
        />
      </Box>
    </Container>
  );
};
