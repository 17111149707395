export const translationPL = {
  translation: {
    register: 'Rejestracja',
    hi: 'Witaj!',
    lorem:
      'Lorem Ipsum jest tekstem stosowanym jako przykładowy wypełniacz w przemyśle poligraficznym. Został po raz pierwszy użyty w XV w. przez nieznanego drukarza do wypełnienia tekstem próbnej książki. Pięć wieków później zaczął być używany przemyśle elektronicznym, pozostając praktycznie niezmienionym. Spopularyzował się w latach 60. XX w. wraz z publikacją arkuszy Letrasetu, zawierających fragmenty Lorem Ipsum, a ostatnio z zawierającym różne wersje Lorem Ipsum oprogramowaniem przeznaczonym do realizacji druków na komputerach osobistych, jak Aldus PageMaker',
    loremShort:
      'Lorem Ipsum jest tekstem stosowanym jako przykładowy wypełniacz w przemyśle poligraficznym. Został po raz pierwszy użyty w XV w. przez nieznanego drukarza do wypełnienia tekstem próbnej książki.',
    fullName: 'Imię i nazwisko:',
    phoneNumber: 'Numer telefonu:',
    email: 'Email',
    password: 'Hasło',
    agree1: 'Wyrażam zgodę na…',
    agree2:
      'Wyrażam zgodę na udział w loterii. Więcej informacji pod adresem: example.pl',
    signUp: 'Zarejestruj się',
    logIn: 'Zaloguj się',
    homePage: 'Strona główna',
    login: 'Zaloguj się',
    logOut: 'Wyloguj',
    quote1:
      'Programy muszą być pisane dla ludzi, aby je czytali, a tylko przy okazji dla maszyn, aby je wykonywały. – Harold Abelson',
    quote2:
      'Każdy głupiec może napisać kod, który komputer zrozumie. Dobry programista pisze kod, który zrozumieją ludzie. – Martin Fowler',
    quote3:
      'Kod jest jak humor. Kiedy musisz go wyjaśniać, jest zły. – Cory House',
    quote4: 'Najpierw rozwiąż problem. Następnie napisz kod. – John Johnson',
    quote5: 'Prostota jest duszą efektywności. – Austin Freeman',
    quote6:
      'Programowanie nie polega na tym, co wiesz; chodzi o to, co możesz wymyślić. – Chris Pine',
    quote7:
      'Najlepszy komunikat o błędzie to ten, który nigdy się nie pojawia. – Thomas Fuchs',
    quote8:
      'Najważniejszą cechą programu jest to, czy osiąga zamierzenie użytkownika. – C.A.R. Hoare',
    privacy_policy_0:
      'Niniejsza Polityka Prywatności opisuje zasady przetwarzania danych osobowych przez [Twoja Firma], z siedzibą w [adres siedziby], wpisaną do rejestru [nazwa rejestru] pod numerem [numer rejestru].',
    privacy_policy_1: '1. Administrator Danych Osobowych',
    privacy_policy_1_1:
      'Administratorem Twoich danych osobowych jest [Twoja Firma] z siedzibą w [adres siedziby]. Możesz skontaktować się z nami pod adresem e-mail: [adres e-mail] lub telefonicznie: [numer telefonu].',
    privacy_policy_2: '2. Cele i Podstawa Przetwarzania Danych',
    privacy_policy_2_1:
      'Przetwarzamy Twoje dane osobowe w następujących celach:',
    privacy_policy_2_1_1:
      '- Świadczenie usług: Przetwarzanie danych jest niezbędne do wykonania umowy, której jesteś stroną, lub do podjęcia działań na Twoje żądanie przed zawarciem umowy (art. 6 ust. 1 lit. b RODO).',
    privacy_policy_2_1_2:
      '- Marketing: W celu przesyłania informacji marketingowych za Twoją zgodą (art. 6 ust. 1 lit. a RODO).',
    privacy_policy_2_1_3:
      '- Spełnienie obowiązków prawnych: Przetwarzamy dane w celu wypełnienia obowiązków prawnych, np. związanych z rachunkowością (art. 6 ust. 1 lit. c RODO).',
    privacy_policy_2_1_4:
      '- Prawnie uzasadnione interesy: W celu prowadzenia analiz, statystyk oraz zabezpieczenia przed roszczeniami (art. 6 ust. 1 lit. f RODO).',
    privacy_policy_3: '3. Rodzaje Przetwarzanych Danych Osobowych',
    privacy_policy_3_1: 'Przetwarzamy następujące kategorie danych osobowych:',
    privacy_policy_3_1_1: '- Dane identyfikacyjne (np. imię i nazwisko)',
    privacy_policy_3_1_2:
      '- Dane kontaktowe (np. adres e-mail, numer telefonu)',
    privacy_policy_3_1_3:
      '- Dane związane z realizacją umowy (np. historia zamówień)',
    privacy_policy_4: '4. Odbiorcy Danych Osobowych',
    privacy_policy_4_1:
      'Twoje dane osobowe mogą być przekazywane następującym kategoriom odbiorców:',
    privacy_policy_4_1_1:
      '- Podmioty przetwarzające dane na nasze zlecenie (np. firmy IT, firmy księgowe)',
    privacy_policy_4_1_2:
      '- Organy publiczne, jeśli wymaga tego obowiązujące prawo',
    privacy_policy_4_1_3:
      '- Inne podmioty współpracujące na podstawie umowy z [Twoja Firma]',
    privacy_policy_5: '5. Przekazywanie Danych Poza EOG',
    privacy_policy_5_1:
      'Twoje dane osobowe nie będą przekazywane poza Europejski Obszar Gospodarczy (EOG), chyba że będzie to niezbędne i zapewnimy odpowiedni stopień ochrony danych.',
    privacy_policy_6: '6. Okres Przechowywania Danych',
    privacy_policy_6_1:
      'Dane osobowe przechowujemy przez okres niezbędny do realizacji celów przetwarzania lub do momentu wycofania zgody, jeśli przetwarzanie odbywa się na jej podstawie. Okres ten może być wydłużony o czas niezbędny do zabezpieczenia lub dochodzenia ewentualnych roszczeń.',
    privacy_policy_7: '7. Twoje Prawa',
    privacy_policy_7_1:
      'Przysługują Ci następujące prawa w związku z przetwarzaniem Twoich danych osobowych:',
    privacy_policy_7_1_1: '- Prawo dostępu do danych',
    privacy_policy_7_1_2: '- Prawo do sprostowania danych',
    privacy_policy_7_1_3:
      '- Prawo do usunięcia danych („prawo do bycia zapomnianym”)',
    privacy_policy_7_1_4: '- Prawo do ograniczenia przetwarzania',
    privacy_policy_7_1_5: '- Prawo do przenoszenia danych',
    privacy_policy_7_1_6: '- Prawo do sprzeciwu wobec przetwarzania',
    privacy_policy_7_1_7: '- Prawo do wycofania zgody',
    privacy_policy_7_2:
      'Aby skorzystać ze swoich praw, skontaktuj się z nami pod adresem e-mail: [adres e-mail].',
    privacy_policy_8: '8. Bezpieczeństwo Danych',
    privacy_policy_8_1:
      'Stosujemy odpowiednie środki techniczne i organizacyjne, aby chronić Twoje dane osobowe przed nieuprawnionym dostępem, utratą, zniszczeniem lub zmianą.',
    privacy_policy_9: '9. Zmiany w Polityce Prywatności',
    privacy_policy_9_1:
      'Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności. O wszelkich zmianach poinformujemy Cię poprzez umieszczenie nowej wersji polityki na naszej stronie internetowej.',
    privacy_policy_10: '10. Kontakt',
    privacy_policy_10_1:
      'W razie pytań dotyczących przetwarzania Twoich danych osobowych prosimy o kontakt pod adresem e-mail: [adres e-mail] lub telefonicznie: [numer telefonu].',
    privacy_policy_10_2:
      'Pamiętaj, aby uzupełnić powyższy dokument o szczegóły specyficzne dla Twojej działalności oraz uzyskać profesjonalną poradę prawną przed jego opublikowaniem.',
    user_creation_error: 'Błąd',
    campaign_not_found: 'Kampania nie istnieje!',
    campaign_inactive: 'Kampania jest nieaktywna.',
    account_already_exists: 'Niestety, ale u tego bukmachera masz już konto.',
  },
};
