export const baseStyles = {
  color: 'inherit',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: '600',
  lineHeight: 1.6,

  '&:hover': {
    color: 'info.light',
    textDecoration: 'none',
  },
};
