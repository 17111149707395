import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';

export const Error404 = () => (
  <Box
    sx={{
      display: 'flex',
      flexFlow: 'wrap column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
    }}
  >
    <Typography variant="h5" text="Strona nie istnieje" gutterBottom />
    <Typography variant="h1" text="ERROR 404" />
  </Box>
);
