import React from 'react';
import MuiDialog from '@mui/material/Dialog';
import { useDialogState } from '@store/useDialogState/useDialogState';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@components/Button';
import { renderIcon } from '@utils/renderIcon';

interface Props {
  children?: React.ReactNode;
  closeAction?: () => void;
  closeName?: string;
  confirmAction: () => void;
  confirmColorButton?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  confirmName?: string;
  disabled?: boolean;
  id: string;
  isIcon?: boolean;
  loading?: boolean;
  width?: number;
}

const Dialog: React.FC<Props> = ({
  id,
  children,
  confirmName,
  confirmAction,
  confirmColorButton = 'primary',
  closeName,
  closeAction,
  width,
  loading,
  disabled,
  isIcon = false,
}) => {
  const { dialogs } = useDialogState();
  const icon = renderIcon({ icon: 'ErrorOutline', color: 'error' });
  const dialog = dialogs[id] || {};
  const { title, open, text, action } = dialog;

  return (
    <MuiDialog open={open}>
      {title && (
        <DialogTitle
          sx={{ padding: 2, alignItems: 'center', display: 'flex', gap: 1 }}
        >
          {isIcon && icon} {title}
        </DialogTitle>
      )}
      {children && (
        <DialogContent sx={{ width, padding: 2 }}>{children}</DialogContent>
      )}
      {text && <DialogContent sx={{ width, padding: 2 }}>{text}</DialogContent>}
      <DialogActions sx={{ justifyContent: 'flex-end', padding: 2, gap: 1 }}>
        {closeAction && (
          <Button
            text={closeName}
            variant="text"
            size="medium"
            onClick={() => closeAction()}
          />
        )}
        <Button
          disabled={disabled}
          loading={loading}
          text={confirmName}
          size="medium"
          color={confirmColorButton}
          onClick={() => {
            action && action();
            confirmAction();
          }}
        />
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
