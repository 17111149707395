import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import ButtonIcon from '@components/ButtonIcon';
import Button from '@components/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { DocumentReference } from '@firebase/firestore';

interface Props {
  bookmakerRef: DocumentReference | null;
  children: React.ReactNode;
  handleStart: () => void;
  loading: boolean;
}

const BookmakerFunction: React.FC<Props> = ({
  bookmakerRef,
  children,
  handleStart,
  loading,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { getBookmaker, bookmaker } = useBookmakerStore();

  useEffect(() => {
    if (bookmakerRef) {
      getBookmaker(bookmakerRef);
    }
  }, [bookmakerRef]);

  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={bookmaker.image} sx={{ width: 32, height: 32 }} />}
        title={bookmaker.name}
        action={
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Button
              startIcon="PlayCircle"
              text="Start"
              onClick={handleStart}
              size="small"
              color="success"
              loading={loading}
            />
            <ButtonIcon
              icon={!expanded ? 'KeyboardArrowDown' : 'KeyboardArrowUp'}
              onClick={() => setExpanded(!expanded)}
            />
          </Box>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default BookmakerFunction;
