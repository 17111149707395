import React, { useEffect } from 'react';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import Grid from '@mui/material/Grid';
import TitleSection from '@components/TitleSection';
import BookmakerItem from '@components/BookmakerItem';
import DialogDeleteConfirm from '@components/DialogDeleteConfirm';
import { DialogsID } from '@enums/dialogs';
import DialogEditBookmaker from '@components/DialogEditBookmaker';

export const ListBookmakers = () => {
  const { bookmakers, getBookmakers } = useBookmakerStore();

  useEffect(() => {
    getBookmakers();
  }, [getBookmakers]);

  return (
    <>
      <TitleSection text="Lista bukmacherów" />

      <Grid container spacing={2}>
        {bookmakers.map((item, i) => (
          <Grid item xs={4} key={i}>
            <BookmakerItem
              bookmakerRef={item.ref}
              image={item.image}
              name={item.name}
              createdAt={item.createdAt}
              variants={item.variants}
            />
          </Grid>
        ))}
      </Grid>

      <DialogEditBookmaker dialogId={DialogsID.EDIT_BOOKMAKER} />
      <DialogDeleteConfirm dialogId={DialogsID.DELETE_BOOKMAKER} />
    </>
  );
};
