import { create } from 'zustand';
import { DialogStore } from '@store/useDialogState/interfaces';
import { DEFAULT_DIALOG_STATE } from '@store/useDialogState/consts';

export const useDialogState = create<DialogStore>((set) => ({
  ...DEFAULT_DIALOG_STATE,

  setDialog: ({ id, title = '', text = '', action, params }) => {
    set((state) => ({
      dialogs: {
        ...state.dialogs,
        [id]: { open: true, title, text, action, params },
      },
    }));
  },
  closeDialog: () => {
    set({ dialogs: {} });
  },
}));
