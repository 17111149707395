import React from 'react';
import { InputInterface } from '@components/Input/interfaces';
import TextField from '@mui/material/TextField';

const Input: React.FC<InputInterface> = ({
  type = 'text',
  name,
  value,
  onChange,
  required = false,
  label,
  disabled,
  error,
  size = 'medium',
  inputProps,
}) => {
  return (
    <TextField
      sx={{ backgroundColor: 'white' }}
      fullWidth
      disabled={disabled}
      label={label}
      required={required}
      error={error}
      variant="outlined"
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      size={size}
      inputProps={inputProps}
    />
  );
};

export default Input;
