import { User } from '@store/useUserStore/interfaces';

const initialUser: User = {
  agreement_1: false,
  agreement_2: false,
  createdAt: { seconds: 0, nanoseconds: 0 },
  email: '',
  name: '',
  phoneNumber: '',
  role: '',
};

export const DEFAULT_USER_STATE = {
  users: [],
  user: initialUser,
  loading: false,
};
