import { getDoc } from '@src/firebase-config';
import { DocumentReference } from '@firebase/firestore';

export const getBookmakerSettings = async (
  bookmakerSettingsRef: DocumentReference,
) => {
  const bookmakerSettingsDoc = await getDoc(bookmakerSettingsRef);
  if (bookmakerSettingsDoc.exists()) {
    return bookmakerSettingsDoc.data() as any;
  }

  throw new Error('Document not found');
};
