import { DocumentReference } from '@firebase/firestore';
import { getDoc } from '@src/firebase-config';
import { Layout } from '@store/useSettingStore/interfaces';

export const getLayout = async (
  layoutRef: DocumentReference,
): Promise<Layout> => {
  const layoutDoc = await getDoc(layoutRef);
  if (layoutDoc.exists()) {
    return layoutDoc.data() as Layout;
  }

  throw new Error('Layout not found');
};
