import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Select from '@components/Select';
import { DataGrid } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import { formatDate, parseTimestamp } from '@utils/helpers';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import TitleSection from '@components/TitleSection';
import CustomLink from '@components/CustomLink';
import { chipStatus } from '@utils/chipStatus';

export const ListCampaigns = () => {
  const { bookmakers, getBookmakers, setSelectBookmaker, selectedBookmaker } =
    useBookmakerStore();
  const { campaigns, loading, getCampaignsByBookmaker } = useCampaignStore();

  useEffect(() => {
    getBookmakers();

    if (selectedBookmaker) {
      getCampaignsByBookmaker(selectedBookmaker.ref);
    }
  }, [getBookmakers]);

  const handleSelectBookmaker = (e: SelectChangeEvent) => {
    const bookmaker = setSelectBookmaker(e.target.value);
    getCampaignsByBookmaker(bookmaker.ref);
  };

  return (
    <>
      <TitleSection text="Lista kampanii" />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            label="Wybierz bukmachera"
            value={selectedBookmaker?.id || ''}
            onChange={handleSelectBookmaker}
            options={bookmakers.map((bookmaker) => ({
              value: bookmaker.id,
              label: bookmaker.name,
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            sx={{ height: 700, backgroundColor: 'white' }}
            loading={loading}
            disableColumnSelector
            disableColumnFilter
            disableRowSelectionOnClick
            columns={[
              {
                field: 'name',
                headerName: 'Nazwa kampanii',
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params) => {
                  const id = params.row.ref?.id;
                  return id ? (
                    <CustomLink to={id} label={params.value} />
                  ) : (
                    <span>{params.value}</span>
                  );
                },
              },
              {
                field: 'createdAt',
                headerName: 'Data utworzenia',
                width: 260,
                disableColumnMenu: true,
                valueFormatter: ({ value }) =>
                  parseTimestamp(value, 'yyyy-MM-dd HH:mm'),
                renderCell: (params) =>
                  formatDate(params.value, 'd MMMM, yyyy - HH:mm'),
              },
              {
                field: 'endAt',
                headerName: 'Data zakończenia',
                width: 260,
                disableColumnMenu: true,
                valueFormatter: ({ value }) =>
                  parseTimestamp(value, 'yyyy-MM-dd HH:mm'),
                renderCell: (params) =>
                  params.value
                    ? formatDate(params.value, 'd MMMM, yyyy - HH:mm')
                    : '---',
              },
              {
                field: 'status',
                headerName: 'Status',
                width: 160,
                align: 'center',
                headerAlign: 'center',
                disableColumnMenu: true,
                renderCell: (params) => chipStatus(params.value),
              },
            ]}
            rows={campaigns}
          />
        </Grid>
      </Grid>
    </>
  );
};
