import React from 'react';
import Dialog from '@components/Dialog';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { DialogsID } from '@enums/dialogs';

const DialogAlert = () => {
  const dialogId = DialogsID.REGISTER;
  const { closeDialog, dialogs } = useDialogState();

  const handleConfirm = () => {
    closeDialog();
  };

  return dialogs[dialogId]?.open ? (
    <Dialog
      id={dialogId}
      confirmAction={handleConfirm}
      confirmName="OK"
      width={300}
      isIcon
    />
  ) : null;
};

export default DialogAlert;
