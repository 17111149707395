export enum Routes {
  HOME = '/',
  LOGIN = '/admin-login',
  ADMIN_PANEL = '/admin-panel',
  PRIVACY_POLICY = '/privacy-policy',
  SETTINGS = '/admin-panel/settings',
}

export enum AdminPanelRoutes {
  ADD_BOOKMAKER = 'add-bookmaker',
  ADD_CAMPAIGN = 'add-campaign',
  LAYOUTS = 'layouts',
  VERIFICATIONS = 'verifications',
  FUNCTIONS = 'functions',
  RULES = 'rules',
  LINK_GENERATOR = 'link-generator',
  LIST_BOOKMAKER = 'list-bookmaker',
  LIST_CAMPAIGNS = 'list-campaigns',
  LIST_USERS = 'list-users',
  SETTINGS = 'settings',
}
