import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { FormAlertInterface } from '@components/FormAlert/interfaces';

const FormAlert: React.FC<FormAlertInterface> = ({ errors }) => {
  const normalizeError = (error: any): string => {
    if (typeof error === 'string') return error;
    if (Array.isArray(error)) return error.map(normalizeError).join(', ');
    if (typeof error === 'object' && error !== null)
      return JSON.stringify(error);
    return 'Unknown error';
  };

  const errorMessages = Object.values(errors)
    .flatMap((error) =>
      Array.isArray(error) ? error.map(normalizeError) : normalizeError(error),
    )
    .filter(Boolean);

  if (errorMessages.length === 0) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {errorMessages.map((error, index) => (
        <Alert severity="error" key={index}>
          {error}
        </Alert>
      ))}
    </Box>
  );
};

export default FormAlert;
