import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { renderIcon } from '@utils/renderIcon';
import { MenuItemInterface } from '@components/MenuItem/interfaces';

const MenuItem: React.FC<MenuItemInterface> = ({
  icon,
  name,
  secondName,
  selected,
  onClick,
}) => {
  return (
    <ListItemButton selected={selected} onClick={onClick}>
      {icon && (
        <ListItemIcon sx={{ minWidth: 38 }}>
          {renderIcon({ icon })}
        </ListItemIcon>
      )}
      <ListItemText primary={name} secondary={secondName} />
    </ListItemButton>
  );
};

export default MenuItem;
