import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '@src/firebase-config';

export const uploadImage = async (image: File, place: string) => {
  if (!image) return;

  try {
    const storageRef = ref(storage, `${place}/${image.name}`);
    await uploadBytes(storageRef, image);
    return await getDownloadURL(storageRef);
  } catch (error) {
    console.error(error);
  }
};
