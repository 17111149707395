import { db, getDocs, collection } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { Layout } from '@store/useSettingStore/interfaces';

export const getLayouts = async () => {
  try {
    const snapshot = await getDocs(collection(db, Collection.LAYOUTS));
    if (snapshot.empty) return [];

    const list: Layout[] = snapshot.docs.map((doc) => ({
      id: doc.id,
      ref: doc.ref,
      name: doc.data().name,
      component: doc.data().component,
    }));

    return list.length ? list : [];
  } catch (error) {
    console.error('Error fetching layouts:', error);
    return [];
  }
};
