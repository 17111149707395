import React from 'react';
import { useDialogState } from '@store/useDialogState/useDialogState';
import Dialog from '@components/Dialog';

interface Props {
  dialogId: string;
}

const DialogDeleteConfirm: React.FC<Props> = ({ dialogId }) => {
  const { closeDialog, dialogs } = useDialogState();

  return dialogs[dialogId]?.open ? (
    <Dialog
      id={dialogId}
      confirmName="Tak"
      confirmAction={() => closeDialog()}
      closeName="Nie"
      closeAction={() => closeDialog()}
      isIcon
      confirmColorButton="error"
    />
  ) : null;
};

export default DialogDeleteConfirm;
