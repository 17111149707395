import { collection, db, getDocs, query, where } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { User } from '@store/useUserStore/interfaces';

export const getUsers = async () => {
  const usersQuery = query(
    collection(db, Collection.USERS),
    where('role', '==', 'user'),
  );

  const snapshot = await getDocs(usersQuery);
  if (snapshot.empty) return [];

  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  if (list.length) {
    return list as User[];
  }
};
