import React from 'react';
import { Outlet } from 'react-router-dom';
import TitleSection from '@components/TitleSection';
import HorizontalMenu from '@components/HorizontalMenu';

export const Settings = () => {
  return (
    <>
      <TitleSection text="Ustawienia">
        <HorizontalMenu />
      </TitleSection>

      <Outlet />
    </>
  );
};
