import { collection, db, getDocs } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { Bookmaker } from '@store/useBookmakerStore/interfaces';

export const getBookmakers = async () => {
  const snapshot = await getDocs(collection(db, Collection.BOOKMAKERS));
  if (snapshot.empty) return [];

  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  if (list.length) {
    return list as Bookmaker[];
  }
};
