import { DocumentReference } from '@firebase/firestore';
import { collection, db, getDocs, query, where } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { UserCampaign } from '@store/useUserCampaignStore/interfaces';

export const getUserCampaigns = async (campaignRef: DocumentReference) => {
  const campaignsQuery = query(
    collection(db, Collection.USER_CAMPAIGNS),
    where('campaignRef', '==', campaignRef),
  );

  const snapshot = await getDocs(campaignsQuery);
  if (snapshot.empty) return [];

  return snapshot.docs.map((doc) => ({
    ...doc.data(),
  })) as UserCampaign[];
};
