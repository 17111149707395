import { Code } from '@store/useCodeStore/interfaces';
import { StatusCode } from '@enums/status';

export const initialCode: Omit<Code, 'id'> = {
  code: '',
  status: StatusCode.AVAILABLE,
  campaignRef: null,
};

export const DEFAULT_CODE_STATE = {
  loading: false,
  codes: [],
  codesInput: '',
  code: initialCode,
};
