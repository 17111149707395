import React, { Fragment, useEffect } from 'react';
import { FieldArray, useFormik, FormikProvider } from 'formik';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import useNotifier from '@utils/useNotifier';
import Dialog from '@components/Dialog';
import Grid from '@mui/material/Grid';
import Input from '@components/Input';
import Box from '@mui/material/Box';
import InputUpload from '@components/InputUpload';
import Divider from '@mui/material/Divider';
import Typography from '@components/Typography';
import ButtonIcon from '@components/ButtonIcon';
import Button from '@components/Button';
import { successMessages } from '@messages/successMessages';
import { uploadImage } from '@utils/uploadImage';

interface Props {
  dialogId: string;
}

interface Variant {
  label: string;
  value: string;
}

interface FormValues {
  image: File | string | undefined;
  name: string;
  variants: Variant[];
}

const DialogEditBookmaker: React.FC<Props> = ({ dialogId }) => {
  const { notify } = useNotifier();
  const { closeDialog, dialogs } = useDialogState();
  const { getBookmaker, bookmaker, updateBookmaker } = useBookmakerStore();

  const dialogParams = dialogs[dialogId]?.params;

  useEffect(() => {
    if (dialogParams) {
      getBookmaker(dialogParams.bookmakerRef);
    }
  }, [dialogParams]);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: bookmaker.name,
      image: bookmaker.image,
      variants: bookmaker.variants,
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (typeof values.image === 'string') {
        updateBookmaker(dialogParams.bookmakerRef, values);
        notify(successMessages.editBookmaker, 'success');
      } else {
        if (values.image) {
          const urlImage = await uploadImage(values.image, 'bookmakers');
          const updateValues = {
            ...values,
            image: urlImage,
          };
          updateBookmaker(dialogParams.bookmakerRef, updateValues);
          notify(successMessages.editBookmaker, 'success');
        }
      }

      resetForm();
      closeDialog();
    },
  });

  return dialogs[dialogId]?.open ? (
    <Dialog
      id={dialogId}
      confirmName="Zapisz"
      confirmAction={formik.handleSubmit}
      closeName="Anuluj"
      closeAction={() => {
        formik.resetForm();
        closeDialog();
      }}
      width={500}
    >
      <Box sx={{ display: 'flex', flexFlow: 'wrap column', padding: 1 }}>
        <FormikProvider value={formik}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                name="name"
                label="Nazwa bukmachera"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  flexFlow: 'nowrap row',
                }}
              >
                <InputUpload
                  name="image"
                  text="Dodaj logotyp"
                  onChange={(e) => {
                    formik.setFieldValue(
                      'image',
                      e.target.files ? e.target.files[0] : null,
                    );
                  }}
                />
                {typeof formik.values.image !== 'string' && (
                  <Typography
                    text={formik.values.image?.name}
                    variant="body1"
                    color="success"
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider sx={{ marginBottom: 2 }} />
                  <Typography text="Dodaj warianty" variant="caption" />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="variants"
                    render={({ push, remove }) => (
                      <Grid container spacing={2}>
                        {formik.values.variants.map((_, index) => (
                          <Fragment key={index}>
                            <Grid item xs={6}>
                              <Input
                                name={`variants[${index}].label`}
                                label="Klucz"
                                value={formik.values.variants[index].label}
                                size="small"
                                onChange={formik.handleChange}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Input
                                name={`variants[${index}].value`}
                                label="Wartość"
                                value={formik.values.variants[index].value}
                                size="small"
                                onChange={formik.handleChange}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  height: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <ButtonIcon
                                  icon="RemoveCircleOutline"
                                  onClick={() => remove(index)}
                                />
                              </Box>
                            </Grid>
                          </Fragment>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            text="Dodaj wariant"
                            variant="text"
                            startIcon="AddCircleOutline"
                            onClick={() => push({ value: '', label: '' })}
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormikProvider>
      </Box>
    </Dialog>
  ) : null;
};

export default DialogEditBookmaker;
