import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CustomLinkInterface } from '@components/CustomLink/interface';
import Box from '@mui/material/Box';
import { baseStyles } from '@components/CustomLink/styles';

const CustomLink: React.FC<CustomLinkInterface> = ({ to, onClick, label }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box component={RouterLink} to={to} onClick={handleClick} sx={baseStyles}>
      {label}
    </Box>
  );
};

export default CustomLink;
