import { collection, db, getDocs, query, where } from '@src/firebase-config';
import { Collection } from '@enums/collection';
import { DocumentReference } from '@firebase/firestore';

export const doesCampaignExist = async (bookmakerRef: DocumentReference) => {
  const campaignQuery = query(
    collection(db, Collection.CAMPAIGNS),
    where('bookmakerRef', '==', bookmakerRef),
  );
  const snapshot = await getDocs(campaignQuery);

  return !snapshot.empty;
};
