import { useAuthStore } from '@store/useAuthStore/useAuthStore';
import { auth, signInWithEmailAndPassword } from '@src/firebase-config';

export const signInUser = async () => {
  const { email, password } = useAuthStore.getState();
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password,
  );

  return userCredential.user;
};
