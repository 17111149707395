import { create } from 'zustand';
import { DEFAULT_BOOKMAKER_STATE } from '@store/useBookmakerStore/consts';
import { Bookmaker, BookmakerStore } from '@store/useBookmakerStore/interfaces';
import { getBookmakers } from '@utils/api/bookmakers/getBookmakers';
import { DocumentReference } from '@firebase/firestore';
import { getBookmaker } from '@utils/api/bookmakers/getBookmaker';
import { addBookmaker } from '@utils/api/bookmakers/addBookmaker';
import { deleteBookmaker } from '@utils/api/bookmakers/deleteBookmaker';
import { getBookmakerRef } from '@utils/api/bookmakers/getBookmakerRef';
import { updateBookmaker } from '@utils/api/bookmakers/updateBookmaker';

export const useBookmakerStore = create<BookmakerStore>((set, get) => ({
  ...DEFAULT_BOOKMAKER_STATE,

  setSelectBookmaker: (bookmakerId: string) => {
    const bookmakerRef = getBookmakerRef(bookmakerId);
    const selectedBookmaker = { id: bookmakerId, ref: bookmakerRef };
    set({ selectedBookmaker });

    return selectedBookmaker;
  },
  addBookmaker: async (data: Bookmaker) => {
    try {
      set({ loading: true });
      await addBookmaker(data);
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error('Error adding bookmaker: ', error);
    }
  },
  deleteBookmaker: async (bookmakerRef: DocumentReference) => {
    try {
      set({ loading: true });
      await deleteBookmaker(bookmakerRef);
      get().getBookmakers();
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      console.error('Error deleting bookmaker: ', error);
    }
  },
  getBookmaker: async (bookmakerRef: DocumentReference) => {
    try {
      set({ loading: true });
      const bookmaker = await getBookmaker(bookmakerRef);
      set({ bookmaker });
      set({ loading: false });
    } catch (error) {
      console.error('Error fetching bookmaker: ', error);
    }
  },
  getBookmakers: async () => {
    try {
      const bookmakers = await getBookmakers();
      set({ bookmakers });
    } catch (error) {
      console.error('Error fetching bookmakers: ', error);
    }
  },
  updateBookmaker: async (bookmakerRef: DocumentReference, data: object) => {
    try {
      set({ loading: true });
      await updateBookmaker(bookmakerRef, data);
      get().getBookmakers();
      set({ loading: false });
    } catch (error) {
      console.error('Error updating bookmaker: ', error);
    }
  },
  setBookmaker: (bookmaker: Partial<Bookmaker>) =>
    set((state) => ({
      bookmaker: { ...state.bookmaker, ...bookmaker },
    })),
  resetBookmakerStore: () => set({ ...DEFAULT_BOOKMAKER_STATE }),
}));
