import React from 'react';
import * as Icons from '@mui/icons-material';

interface Props {
  icon: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  fontSize?: 'small' | 'inherit' | 'medium' | 'large';
}

export const renderIcon: React.FC<Props> = ({ icon, fontSize, color }) => {
  const IconComponent = Icons[icon as keyof typeof Icons];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent fontSize={fontSize} color={color} />;
};
