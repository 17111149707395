import React from 'react';
import { useFormik } from 'formik';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { useSettingStore } from '@store/useSettingStore/useSettingStore';
import useNotifier from '@utils/useNotifier';
import Dialog from '@components/Dialog';
import Input from '@components/Input';
import Box from '@mui/material/Box';
import { successMessages } from '@messages/successMessages';

interface Props {
  dialogId: string;
}

const DialogEditLayout: React.FC<Props> = ({ dialogId }) => {
  const { notify } = useNotifier();
  const { closeDialog, dialogs } = useDialogState();
  const { updateLayout } = useSettingStore();

  const dialogParams = dialogs[dialogId]?.params || { component: '', name: '' };

  const formik = useFormik({
    initialValues: {
      component: dialogParams.component,
      name: dialogParams.name,
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      updateLayout(dialogParams.ref, { ...values });
      notify(successMessages.editLayout, 'success');

      resetForm();
      closeDialog();
    },
  });

  return dialogs[dialogId]?.open ? (
    <Dialog
      id={dialogId}
      confirmName="Zapisz"
      confirmAction={formik.handleSubmit}
      closeName="Anuluj"
      closeAction={() => {
        formik.resetForm();
        closeDialog();
      }}
      width={500}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'wrap column',
          gap: 2,
          padding: 1,
        }}
      >
        <Input
          name="name"
          label="Nazwa szablonu"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <Input
          name="component"
          label="Nazwa komponentu"
          value={formik.values.component}
          onChange={formik.handleChange}
        />
      </Box>
    </Dialog>
  ) : null;
};

export default DialogEditLayout;
