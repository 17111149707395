import React from 'react';
import ButtonIcon from '@components/ButtonIcon';
import { successMessages } from '@messages/successMessages';
import useNotifier from '@utils/useNotifier';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { DeleteBookmakerInterface } from '@components/BookmakerItem/interfaces';
import { useDialogState } from '@store/useDialogState/useDialogState';
import { DialogsID } from '@enums/dialogs';

const DeleteBookmaker: React.FC<DeleteBookmakerInterface> = ({
  bookmakerRef,
  name,
  disabled,
}) => {
  const { notify } = useNotifier();
  const { setDialog } = useDialogState();
  const { deleteBookmaker } = useBookmakerStore();

  const handleClick = async () => {
    setDialog({
      id: DialogsID.DELETE_BOOKMAKER,
      title: 'Usuń bukmachera',
      text: 'Czy na pewno chcesz usunąć bukmachera ' + name + '?',
      action: () => {
        if (bookmakerRef) {
          deleteBookmaker(bookmakerRef);
          notify(successMessages.deleteBookmaker + ': ' + name, 'success');
        }
      },
    });
  };

  return (
    <ButtonIcon
      icon="Delete"
      color="error"
      onClick={handleClick}
      disabled={disabled}
    />
  );
};

export default DeleteBookmaker;
