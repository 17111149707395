export const DEFAULT_SETTING_STATE = {
  loading: false,
  loadingUpdate: false,
  loadingSimulate: false,

  layouts: [],
  fortunaPL: {
    docRef: null,
    bookmakerRef: null,
    daysInterval: 1,
    sport_rfd_amt: '',
    sport_rfd_cnt: '',
    total_deposit_amt: '',
    total_deposit_cnt: '',
  },
};
