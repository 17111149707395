import React from 'react';
import { LayoutAdminInterface } from '@components/LayoutAdmin/interfaces';
import {
  contentStyles,
  logotypeStyles,
  mainStyles,
  sidebarStyles,
  wrapperStyles,
} from '@components/LayoutAdmin/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import VerticalMenu from '@components/VerticalMenu';
import HeaderAdmin from '@components/HeaderAdmin';
import {
  MENU_BOOKMAKER,
  MENU_CAMPAIGN,
  MENU_DASHBOARD,
  MENU_USER,
} from '@components/VerticalMenu/helpers';
import Typography from '@components/Typography';

const LayoutAdmin: React.FC<LayoutAdminInterface> = ({ children }) => {
  return (
    <Box sx={wrapperStyles}>
      <Box sx={sidebarStyles}>
        <Box sx={logotypeStyles}>
          <Typography text="WATCHCODE.net" variant="h5" />
        </Box>
        <VerticalMenu
          menuBookmaker={MENU_BOOKMAKER}
          menuCampaign={MENU_CAMPAIGN}
          menuDashboard={MENU_DASHBOARD}
          menuUser={MENU_USER}
        />
      </Box>
      <Box sx={mainStyles}>
        <HeaderAdmin />
        <Box sx={contentStyles}>
          <Container>{children}</Container>
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutAdmin;
