import { DocumentReference } from '@firebase/firestore';
import {
  collection,
  db,
  deleteDoc,
  getDocs,
  query,
  where,
} from '@src/firebase-config';

export const deleteCodes = async (campaignRef: DocumentReference) => {
  const codesQuery = query(
    collection(db, 'codes'),
    where('campaignRef', '==', campaignRef),
  );
  const codesSnapshot = await getDocs(codesQuery);
  const deleteCodesPromises = codesSnapshot.docs.map((codeDoc) =>
    deleteDoc(codeDoc.ref),
  );

  await Promise.all(deleteCodesPromises);
};
