import React from 'react';
import { VerticalMenuInterface } from '@components/VerticalMenu/interfaces';
import { useBookmakerStore } from '@store/useBookmakerStore/useBookmakerStore';
import { useCampaignStore } from '@store/useCampaignStore/useCampaignStore';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@components/MenuItem';

const VerticalMenu: React.FC<VerticalMenuInterface> = ({
  menuCampaign,
  menuDashboard,
  menuBookmaker,
  menuUser,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { resetBookmakerStore } = useBookmakerStore();
  const { resetCampaignStore } = useCampaignStore();

  const currentPath = location?.pathname.split('/').pop();
  const handleClick = (path: string) => {
    navigate(path);
    resetCampaignStore();
    resetBookmakerStore();
  };

  return (
    <>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            <Divider textAlign="left">DASHBOARD</Divider>
          </ListSubheader>
        }
      >
        {menuDashboard.map((item, i) => (
          <MenuItem
            key={i}
            name={item.name}
            icon={item.icon}
            onClick={() => handleClick(item.path)}
            selected={currentPath === 'admin-panel'}
          />
        ))}
      </List>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            <Divider textAlign="left">BUKMACHER</Divider>
          </ListSubheader>
        }
      >
        {menuBookmaker.map((item, i) => (
          <MenuItem
            key={i}
            name={item.name}
            icon={item.icon}
            onClick={() => handleClick(item.path)}
            selected={currentPath === item.path}
          />
        ))}
      </List>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            <Divider textAlign="left">KAMPANIA</Divider>
          </ListSubheader>
        }
      >
        {menuCampaign.map((item, i) => (
          <MenuItem
            key={i}
            name={item.name}
            icon={item.icon}
            onClick={() => handleClick(item.path)}
            selected={currentPath === item.path}
          />
        ))}
      </List>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            <Divider textAlign="left">UŻYTKOWNICY</Divider>
          </ListSubheader>
        }
      >
        {menuUser.map((item, i) => (
          <MenuItem
            key={i}
            name={item.name}
            icon={item.icon}
            onClick={() => handleClick(item.path)}
            selected={currentPath === item.path}
          />
        ))}
      </List>
    </>
  );
};

export default VerticalMenu;
