import React from 'react';
import Default from '@src/layouts/RegisterForm/default';
import {
  FormValues,
  LayoutRegisterForm,
  LayoutRegisterFormSwitcherInterface,
} from '@src/layouts/RegisterForm/interfaces';
import { useParams } from 'react-router-dom';
import { FormikProps, useFormik } from 'formik';
import { validationSchemaRegister } from '@src/validationSchema/validations';
import { useRegisterStore } from '@store/useRegisterStore/useRegisterStore';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

const layoutMap: Record<string, React.FC<LayoutRegisterForm>> = {
  default: Default,
};

const LayoutRegisterFormSwitcher: React.FC<
  LayoutRegisterFormSwitcherInterface
> = ({ layoutName = 'default' }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setField, registerUserAnonymous } = useRegisterStore();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const SelectedLayout = layoutMap[layoutName];

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
      agreement_1: false,
      agreement_2: false,
    },
    validationSchema: validationSchemaRegister,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      if (!executeRecaptcha) return;

      const token = await executeRecaptcha('register');

      setField('name', values.name);
      setField('phoneNumber', values.phoneNumber);
      setField('email', values.email);
      setField('agreement_1', values.agreement_1);
      setField('agreement_2', values.agreement_2);

      if (params.campaign) {
        await registerUserAnonymous(params.campaign, token, t);
      }

      resetForm();
    },
  });

  return <SelectedLayout formik={formik} />;
};

export default LayoutRegisterFormSwitcher;
