export const DialogsID = {
  ADD_CODES: 'dialog_add_codes_id',
  GENERATE_LINK: 'dialog_generate_link_id',
  REGISTER: 'dialog_register_id',
  EDIT_LAYOUT: 'dialog_edit_layout_id',
  EDIT_BOOKMAKER: 'dialog_edit_bookmaker_id',
  EDIT_CAMPAIGN: 'dialog_edit_campaign_id',
  DELETE_BOOKMAKER: 'dialog_delete_bookmaker_id',
  DELETE_CAMPAIGN: 'dialog_delete_campaign_id',
  DELETE_LAYOUT: 'dialog_delete_layout_id',
};
