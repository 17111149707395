export const DEFAULT_AUTH_STATE = {
  loading: false,
  error: null,
  initialized: false,
  progress: 0,
  email: '',
  password: '',
  user: null,
  role: '',
  superAdmin: false,
  language: undefined,
};
