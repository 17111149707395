import { DocumentReference } from '@firebase/firestore';
import { Collection } from '@enums/collection';
import { collection, db, getDocs, query, where } from '@src/firebase-config';
import { UserBookmaker } from '@store/useUserBookmakerStore/interfaces';

export const getUserBookmakerByUserRef = async (userRef: DocumentReference) => {
  const bookmakersQuery = query(
    collection(db, Collection.USER_BOOKMAKERS),
    where('userRef', '==', userRef),
  );

  const snapshot = await getDocs(bookmakersQuery);
  if (snapshot.empty) return [];

  const list = snapshot.docs.map((doc) => ({
    id: doc.id,
    ref: doc.ref,
    ...doc.data(),
  }));

  return list as UserBookmaker[];
};
